import axios from "axios";

//#region System User Login APIs

export const login = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "users/loginUser",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

//#endregion

//#region Experience APIs
export const getExperience = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/Experience/GetAll");
};

export const addEditExperience = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Experience/POST",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteExperience = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Experience/Delete",
    requestObject
  );
};
//#endregion

//#region Education APIS

export const getEducation = (page, pageSize) => {
  return axios.get(process.env.REACT_APP_API_URL + "userEducation/getAll-userEducation",{
    params: {
      page: page,
      pageSize: pageSize,
    },
  });
};

export const addEducation = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "userEducation/postEducation",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const editEducation = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "userEducation/updateEducation",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteEducation = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "userEducation/deactivate-study",
    requestObject
  );
};
export const getJobType = () => {
  return axios.get(process.env.REACT_APP_API_URL + "job-type/getAll-jobTypes");
};

export const addJobType = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "job-type/addJob-types",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const editJobType = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "job-type/update-jobTypes",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteJobType = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "job-type/delete-jobTypes",
    requestObject
  );
};
export const getJobSkill = () => {
  return axios.get(process.env.REACT_APP_API_URL + "job-skills/getAll-jobSkills");
};

export const addJobSkill = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "job-skills/addSkills",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const editJobSkill = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "job-skills/update-jobSkill",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteJobSkill = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "job-skills/delete-jobSkill",
    requestObject
  );
};
export const getCategories = (page = 1, pageSize = 10) => {
  return axios.get(process.env.REACT_APP_API_URL + "jobCategories/get-allCategory", {
    params: {
      page,
      pageSize
    }
  });
};
export const addEditCategory = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "jobCategories/newCategory",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};
export const deleteCategory = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "jobCategories/delete-category",
    requestObject
  );
};

//#endregion

//#region Spacialism APIS

export const getSpecialism = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/Specialism/GetAll");
};

export const addEditSpecialism = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Specialism/POST",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteSpecialism = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Specialism/Delete",
    requestObject
  );
};

//#endregion

//#region Training Direction APIS

export const getTrainingDirection = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/TrainingDirection/GetAll"
  );
};

export const addEditTrainingDirection = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/TrainingDirection/POST",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteTrainingDirection = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/TrainingDirection/Delete",
    requestObject
  );
};

//#endregion

//#region Job APIS

export const getJobs = (page, pageSize) => {
  
  return axios.get(process.env.REACT_APP_API_URL + "jobPosting/dashboard-getAll-jobPosting", {
    params: { page, pageSize },
  });
};

export const addEditJob = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "jobPosting/newjob-posting", data, {
    ["axios-retry"]: {
      retries: 5,
    },
    headers:{
      "Content-Type":'multipart/form-data'
    }
  });
};

export const addEditJobStatus = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "jobPosting/update-status",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteJob = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Job/Delete",
    requestObject
  );
};

//#endregion

export const getCVs= () => {
  return axios.get(process.env.REACT_APP_API_URL + "userCv/get-allCvs")
};
//#region Client APIS

export const getClient = () => {
  return axios.get(process.env.REACT_APP_API_URL + "clients/getAll-client");
};

export const addClient = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "clients/newClient", data, {
    ["axios-retry"]: {
      retries: 5,
    },
  });
};
export const editClient = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "clients/update-client", data, {
    ["axios-retry"]: {
      retries: 5,
    },
  });
};

export const deleteClient = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "clients/deactivate-client",
    requestObject
  );
};

export const getIndustries = () => {
  return axios.get(process.env.REACT_APP_API_URL + "industries/getAll-industries");
};

export const deleteIndustries = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "industries/delete-industries",
    requestObject
  );
};
export const getImpact= () => {
  return axios.get(process.env.REACT_APP_API_URL + "impactNo/getAll-impactNo");
};
export const addImpact = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "impactNo/add-impactNo", data, {
    ["axios-retry"]: {
      retries: 5,
    },
  });
};
export const editImpact = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "impactNo/update-impactNo", data, {
    ["axios-retry"]: {
      retries: 5,
    },
  });
};
export const deleteImpact= (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "impactNo/delete-impactNo",
    requestObject
  );
};

//#endregion

//#region Testimonial APIS

export const getTestimonials = (page, pageSize) => {
  return axios.get(process.env.REACT_APP_API_URL + "testimonials/getAll-testimonilas", {
    params: { page, pageSize },
  });
};

export const addEditTestimonials = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "testimonials/addTestimonilas",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteTestimonials = (id) => {
  var requestObject = {
    _id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "testimonials/delete-testimonilas",
    requestObject
  );
};

//#endregion

//#region Technology APIS

export const getTechnology = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/Technology/GetAll");
};

export const addEditTechnology = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Technology/POST",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deleteTechnology = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Technology/Delete",
    requestObject
  );
};

//#endregion

//#region Services APIS

export const getServices = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/Services/GetAll");
};

export const addEditServices = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "api/Services/POST", data, {
    ["axios-retry"]: {
      retries: 5,
    },
  });
};

export const deleteServices = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Services/Delete",
    requestObject
  );
};

//#endregion

//#region Quotation APIS

export const getQuotations = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/Quotations/GetAll");
};

export const deleteQuotations = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Quotations/Delete",
    requestObject
  );
};

//#endregion

//#region Blogs APIS

export const getBlogs = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/Blogs/GetAll");
};

export const addEditBlogs = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "api/Blogs/POST", data, {
    ["axios-retry"]: {
      retries: 5,
    },
  });
};

export const deleteBlogs = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Blogs/Delete",
    requestObject
  );
};

//#endregion

//#region Portfolio APIS

export const getPortfolio = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/Portfolio/GetAll");
};

export const addEditPortfolio = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Portfolio/POST",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const deletePortfolio = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Portfolio/Delete",
    requestObject
  );
};

//#endregion

//#region Portfolio Images APIS

export const getPortfolioImages = (id) => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "api/Portfolio/GetAllImagesByPortfolioId?Id=" +
      id
  );
};

export const addEditPortfolioImages = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Portfolio/ProjectImagesPost",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};
 
export const deletePortfolioImages = (id) => {
  var requestObject = {
    Id: id,
  };
  return axios.post(
    process.env.REACT_APP_API_URL + "api/Portfolio/Delete",
    requestObject
  );
};
export const getApplicantDataById = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}jobPosting/get-jobPost`, {
    params: {
      _id: id
    }
  });
};
export const getJobRelatedCvData = (id) => {
  
  return axios.get(`${process.env.REACT_APP_API_URL}jobPosting/getApplicants-jobPosting`, {
    params: {
      job_id: id
    }
  });
};

 export const lookUpDataForJobPosting = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "lookup/miscellaneous-data"
  );
 }
 export const getSubscribersApiCall = (page, pageSize) => {
  return axios.get(process.env.REACT_APP_API_URL + "subscribe/getAllEmail", {
    params: { page, pageSize },
  });
 }
 export const fetchContactData = (page, pageSize) => {
  return axios.get(process.env.REACT_APP_API_URL + "contactForm/get-allContactus", {
    params: { page, pageSize },
  });
};
 export const fetchRequestProposals = (page, pageSize) => {
  return axios.get(process.env.REACT_APP_API_URL + "proposal-form/get-allProposals", {
    params: { page, pageSize },
  });
};

//#endregion
