import React, { useState, useEffect } from "react";
import { X } from "react-feather";

export default function UploadImage({
  onImageSelection,
  thumbnailImageToPass,
  uploadedBase64Image,
}) {
  var [image, setImage] = useState("");
  let [receivedImage, setReceivedImage] = useState("");

  useEffect(() => {
    if (thumbnailImageToPass == null || thumbnailImageToPass == "") {
      setImage("");
      setReceivedImage("");
    } else {
      setImage("props");
      setReceivedImage(thumbnailImageToPass);
    }
    console.log("thumbnailImageToPass", thumbnailImageToPass);
  }, [thumbnailImageToPass]);

  const onImageUpload = (e) => {
    const fileType = e.target.files[0];
    // debugger;
    if (fileType.type == "image/jpeg" || fileType.type == "image/png") {
      setImage((image = e.target.files[0]));
      onImageSelection(image);
    } else alert("Upload Image Only");
  };

  const onCrossButtonClickListener = () => {
    setImage((image = ""));
    onImageSelection(image);
  };

  return (
    <div className="start__up__container__form__input__box__content__upload__wrapper">
      <div className="start__up__container__form__input__box__content__upload">
        <input
          type="file"
          className="start__up__container__form__input__box__content__upload__btn"
          onChange={(e) => {
            onImageUpload(e);
          }}
        />
        Choose Image
      </div>
      {image === "" ? null : (
        <div className="start__up__container__form__input__box__content__upload__uploaded">
          <button
            className="start__up__container__form__input__box__content__upload__uploaded__close"
            onClick={() => {
              onCrossButtonClickListener();
            }}
          >
            <X size={15} color="currentColor" />
          </button>
          <iframe
            src={
              image == "props"
                ? process.env.REACT_APP_API_URL + receivedImage
                : URL.createObjectURL(image)
            }
            alt={image}
            className="start__up__container__form__input__box__content__upload__uploaded__img"
          />
        </div>
      )}
    </div>
  );
}
