import React from "react";
import { Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class ChartsPage extends React.Component {
  state = {
    dataDoughnut: {
      labels: ["Red", "Green", "Yellow", "Grey", "Dark Grey"],
      datasets: [
        {
          data: [300, 50, 100, 40, 120],
          backgroundColor: [
            "#B5D29C",
            "#79AE4D",
            "#6ba53a",
            "#203111",
            "#55842E",
          ],
          hoverBackgroundColor: [
            "#B5D29C",
            "#79AE4D",
            "#6ba53a",
            "#203111",
            "#55842E",
          ],
        },
      ],
    },
  };

  render() {
    return (
      <Doughnut data={this.state.dataDoughnut} options={{ responsive: true }} />
    );
  }
}

export default ChartsPage;
