import React, { useState } from "react";
import "./JobPostingPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import isNullOrEmpty from "../Utils/TextUtils";
import { addEditSpecialism } from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";
import { useHistory, useLocation } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";

export default function SpecialisimForm() {
  //#region Variables

  const history = useHistory();
  const location = useLocation();

  const [id, setId] = useState(
    location.state ? location.state.state.specialismDataToEdit.id : 0
  );

  let [level, setLevel] = useState(
    location.state ? location.state.state.specialismDataToEdit.name : ""
  );
  const [levelErrorMessage, setLevelErrorMessage] = useState("");
  const [levelErrorMessageVisibility, setLevelErrorMessageVisibility] =
    useState(false);

  let [isFeatured, setIsFeatured] = useState(
    location.state
      ? {
          label:
            location.state.state.specialismDataToEdit.isfeatured == true
              ? "Yes"
              : "No",
          value:
            location.state.state.specialismDataToEdit.isfeatured == true
              ? "Yes"
              : "No",
        }
      : { value: "No", label: "No" }
  );
  const [isFeaturedErrorMessage, setIsFeaturedErrorMessage] = useState("");
  const [
    isFeaturedErrorMessageVisibility,
    setIsFeaturedErrorMessageVisibility,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region APi Call

  const addEditSpecialismApiCall = () => {
    let data = {
      id: id,
      name: level,
      isfeatured: isFeatured.value == "Yes" ? true : false,
    };
    if (isViewValid()) {
      setIsLoading(true);
      addEditSpecialism(data)
        .then((res) => {
          setIsLoading(false);
          if (ERROR_CODES.ADD_EDIT_SUCCESS == res.data.errorCode) {
            history.push("/dashboard/specialism");
          } else {
            console.log(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };
  //#endregion

  //#region Level Validation
  const onLevelTextChangeListener = (event) => {
    setLevel((level = event.currentTarget.value));
    setLevelErrorMessageAndVisibilityAndIsValid(level);
  };
  const setLevelErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setLevelErrorMessageAndVisibility(true, "Enter Specialty");
      return isViewValid;
    } else {
      setLevelErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setLevelErrorMessageAndVisibility = (visibility, text) => {
    setLevelErrorMessageVisibility(visibility);
    setLevelErrorMessage(text);
  };

  //#endregion

  //#region is Featured Validation
  const onIsFeaturedTextChangeListener = (event) => {
    setIsFeatured((isFeatured = event));
    setIsFeaturedErrorMessageAndVisibilityAndIsValid(isFeatured);
  };
  const setIsFeaturedErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setIsFeaturedErrorMessageAndVisibility(true, "Select Featured");
      return isViewValid;
    } else {
      setIsFeaturedErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setIsFeaturedErrorMessageAndVisibility = (visibility, text) => {
    setIsFeaturedErrorMessageVisibility(visibility);
    setIsFeaturedErrorMessage(text);
  };

  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setLevelErrorMessageAndVisibilityAndIsValid(level);
    if (viewValid)
      viewValid = setIsFeaturedErrorMessageAndVisibilityAndIsValid(isFeatured);
    return viewValid;
  };
  //#endregion

  //#region other function

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  //#endregion
  const OptionsFeatured = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <div className="panel__conatainer">
      <div className="panel__heading">Add Specialties</div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Specialty
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="speciality"
                  ClassName="input__box"
                  type="text"
                  value={level}
                  onChange={(event) => onLevelTextChangeListener(event)}
                />
                <div className="input__text__error__msg">
                  {levelErrorMessageVisibility ? levelErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  is Featured
                </div>
              </div>
              <div className="company__details__row__input__box">
                <Select
                  name="colors"
                  options={OptionsFeatured}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={isFeatured}
                  onChange={(event) => onIsFeaturedTextChangeListener(event)}
                />
                <div className="input__text__error__msg">
                  {isFeaturedErrorMessageVisibility
                    ? isFeaturedErrorMessage
                    : null}
                </div>
              </div>
            </div>

            <div className="company__details__row" style={{ marginTop: "3em" }}>
              <div
                className="company__details__row__attribute"
                style={{ width: "300px" }}
              >
                <div
                  className="company__details__row__attribute__heading"
                  style={{ color: "transparent" }}
                >
                  Salary
                </div>
              </div>
              <div
                className="company__details__row__input__box"
                style={{ width: "200px" }}
              >
                <ButtonComponent
                  variant="btn"
                  placeholder="Save"
                  onClick={() => addEditSpecialismApiCall()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
