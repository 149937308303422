import React, { useState, useEffect } from "react";
import "./ApplicantsPanel.scss";
import InputBox from "../components/Inputbox";
import { useHistory, useLocation } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import axios from "axios";
import isNullOrEmpty from "../Utils/TextUtils";
import ButtonComponent from "../components/Button";
import { ERROR_CODES } from "../Constants";

export default function TestimonialForm() {
  const history = useHistory();
  const location = useLocation();

  const [_id, setId] = useState(null);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] = useState(false);
  const [designation, setDesignation] = useState("");
  const [designationErrorMessage, setDesignationErrorMessage] = useState("");
  const [designationErrorMessageVisibility, setDesignationErrorMessageVisibility] = useState(false);
  const [company, setCompany] = useState("");
  const [companyErrorMessage, setCompanyErrorMessage] = useState("");
  const [companyErrorMessageVisibility, setCompanyErrorMessageVisibility] = useState(false);
  const [rating, setRating] = useState(1); // Default rating set to 1
  const [ratingErrorMessage, setRatingErrorMessage] = useState("");
  const [ratingErrorMessageVisibility, setRatingErrorMessageVisibility] = useState(false);
  const [image, setImage] = useState(null);
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [imageErrorMessageVisibility, setImageErrorMessageVisibility] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    if (location.state && location.state.state.testimonialDataToEdit) {
      console.log(location.state.state.testimonialDataToEdit)
      const { _id, name, designation, company, rating, testimonial_image } = location.state.state.testimonialDataToEdit;
      setId(_id);
      setName(name);
      setDesignation(designation);
      setCompany(company);
      setRating(rating);
      setImagePreview("https://api-amglobal.dsmeglobal.com/"+testimonial_image);
      debugger
      setImage(testimonial_image)
    }
  }, [location.state]);

  const addEditTestimonialApiCall = async () => {
    const formData = new FormData();
    if (_id) {
      formData.append("_id", _id);
    }
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("company", company);
    formData.append("rating", rating);
    if (image) formData.append("testimonial_image", image);
    else {
    const { testimonial_image } = location.state.state.testimonialDataToEdit;
      
      formData.append("testimonial_image", testimonial_image);
    }
    
    if (isViewValid()) {
      setIsLoading(true);
      try {
        let response;
        if (_id) {
          // Edit Testimonial
          debugger
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}testimonials/update-testimonilas`,
            formData,
            {
              ["axios-retry"]: {
                retries: 5,
              },
            }
          );
        } else {
          // Add Testimonial
          debugger
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}testimonials/addTestimonilas`,
            formData,
            {
              ["axios-retry"]: {
                retries: 5,
              },
            }
          );
        }
        setIsLoading(false);
        if (response.data.error_code === ERROR_CODES.SUCCESS) {
          history.push("/dashboard/testimonials");
        } else {
          console.log(response.data.errorMessage);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
      }
    }
  };

  const onNameTextChangeListener = (event) => {
    setName(event.currentTarget.value);
    setNameErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setNameErrorMessageAndVisibility(true, "Enter Name");
      return false;
    } else {
      setNameErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setNameErrorMessageAndVisibility = (visibility, text) => {
    setNameErrorMessageVisibility(visibility);
    setNameErrorMessage(text);
  };

  const onDesignationTextChangeListener = (event) => {
    setDesignation(event.currentTarget.value);
    setDesignationErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setDesignationErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setDesignationErrorMessageAndVisibility(true, "Enter Designation");
      return false;
    } else {
      setDesignationErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setDesignationErrorMessageAndVisibility = (visibility, text) => {
    setDesignationErrorMessageVisibility(visibility);
    setDesignationErrorMessage(text);
  };

  const onCompanyTextChangeListener = (event) => {
    setCompany(event.currentTarget.value);
    setCompanyErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setCompanyErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setCompanyErrorMessageAndVisibility(true, "Enter Company");
      return false;
    } else {
      setCompanyErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setCompanyErrorMessageAndVisibility = (visibility, text) => {
    setCompanyErrorMessageVisibility(visibility);
    setCompanyErrorMessage(text);
  };

  const onRatingChange = (event) => {
    const newRating = parseInt(event.target.value, 10);
    setRating(newRating);
    setRatingErrorMessageAndVisibilityAndIsValid(newRating);
  };

  const setRatingErrorMessageAndVisibilityAndIsValid = (ratingValue) => {
    if (ratingValue < 1 || ratingValue > 5) {
      setRatingErrorMessageAndVisibility(true, "Rating must be between 1 and 5");
      return false;
    } else {
      setRatingErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setRatingErrorMessageAndVisibility = (visibility, text) => {
    setRatingErrorMessageVisibility(visibility);
    setRatingErrorMessage(text);
  };

  const onImageFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setImageErrorMessageAndVisibility(false, "");
    } else {
      setImageErrorMessageAndVisibility(true, "Select an image file");
    }
  };

  const setImageErrorMessageAndVisibility = (visibility, text) => {
    setImageErrorMessageVisibility(visibility);
    setImageErrorMessage(text);
  };

  const isViewValid = () => {
    let viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    if (viewValid) viewValid = setDesignationErrorMessageAndVisibilityAndIsValid(designation);
    if (viewValid) viewValid = setCompanyErrorMessageAndVisibilityAndIsValid(company);
    if (viewValid) viewValid = setRatingErrorMessageAndVisibilityAndIsValid(rating);
    if (viewValid) viewValid = !imageErrorMessageVisibility;
    return viewValid;
  };

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  return (
    <div className="panel__container">
      <div className="panel__heading">{_id ? "Edit Testimonial" : "Add Testimonial"}</div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Name</div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Name"
                  ClassName="input__box"
                  type="text"
                  value={name}
                  onChange={onNameTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {nameErrorMessageVisibility ? nameErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Designation</div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Designation"
                  ClassName="input__box"
                  type="text"
                  value={designation}
                  onChange={onDesignationTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {designationErrorMessageVisibility ? designationErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Company</div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Company"
                  ClassName="input__box"
                  type="text"
                  value={company}
                  onChange={onCompanyTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {companyErrorMessageVisibility ? companyErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Rating</div>
              </div>
              <div className="company__details__row__input__box">
                <input
                  type="number"
                  min="1"
                  max="5"
                  value={rating}
                  onChange={onRatingChange}
                  className="rating__input"
                />
                <div className="input__text__error__msg">
                  {ratingErrorMessageVisibility ? ratingErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Image</div>
              </div>
              <div className="company__details__row__input__box">
                <input type="file" onChange={onImageFileChange} />
                <div className="input__text__error__msg">
                  {imageErrorMessageVisibility ? imageErrorMessage : null}
                </div>
                {imagePreview && (
                  <img src={imagePreview} alt="Preview" className="image__preview" />
                )}
              </div>
            </div>
            <div className="category__details__row__input__box" style={{ width: "200px",marginTop: '10px' }}>
              <ButtonComponent
              variant="btn" placeholder={_id ? "Update" : "Add"} onClick={addEditTestimonialApiCall} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
