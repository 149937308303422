import React, { useEffect, useState } from "react";
import "./ApplicantsPanel.scss";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import { getEducation, deleteEducation } from "../APIs/Repos";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";

function ApplicantsRow({ educationDataToPass, onDeleteButtonClickListener }) {
  const history = useHistory();
  return (
    <div className="applicant__row">
      <div className="panel__main__row">
        <div className="row_data_entry_body">{educationDataToPass.study}</div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Edit"
            onClick={() => {
              history.push("/dashboard/education-edit", {
                state: { educationDataToEdit: educationDataToPass },
              });
            }}
          />
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Delete"
            onClick={() => onDeleteButtonClickListener(educationDataToPass)}
          />
        </div>
      </div>
    </div>
  );
}

export default function Education() {
  const [educationList, setEducationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);
  const [deleteConfirmationPopupVisibility, setDeleteConfirmationPopupVisibility] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // New state for delete loading

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getEducationApiCall(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deletePackageHandler = (isDelete) => {
    if (isDelete) {
      setIsDeleting(true); // Set deleting state to true
      deleteEducationApiCall(dataToManipulate);
    } else {
      setDeleteConfirmationPopupVisibility(false);
    }
  };

  const getEducationApiCall = (page, size) => {
    setIsLoading(true);

    getEducation(page, size)
      .then((res) => {
        setIsLoading(false);
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          setEducationList(res.data.data);
          setTotalPages(res.data.totalPages); // Assuming the API returns total pages
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteEducationApiCall = (data) => {
    deleteEducation(data._id)
      .then((res) => {
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          const afterRemove = educationList.filter(
            (element) => element.id !== data.id
          );
          setEducationList(afterRemove);
          setDeleteConfirmationPopupVisibility(false);
          getEducationApiCall(currentPage, pageSize);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDeleting(false); // Set deleting state to false
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="panel__container">
      <div className="panel__heading">
        Education{" "}
        <span style={{ float: "right", width: "100px" }}>
          <Button
            variant="link"
            toPath="/dashboard/education-edit"
            placeholder="Add New"
          />
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <div className="panel__main__heading__row">
          <div className="row_data_entry">Name</div>
        </div>
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : educationList && educationList.length > 0 ? (
          educationList.map((data) => (
            <ApplicantsRow
              key={data.id}
              educationDataToPass={data}
              onDeleteButtonClickListener={(data) => {
                setDataToManipulate(data);
                setDeleteConfirmationPopupVisibility(true);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
        )}
      </div>

      {deleteConfirmationPopupVisibility && (
        <DeleteConfirmationPopup
          onDialogCrossClickedListenerCallBack={deletePackageHandler}
          isDeleting={isDeleting} // Pass isDeleting state to the popup
        />
      )}

      <div className="pagination">
        {currentPage > 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="simple-button"
          >
            Previous
          </button>
        )}
        <span>
          Page {currentPage} of {totalPages}
        </span>
        {currentPage < totalPages && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="simple-button"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
