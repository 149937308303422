import React, { useEffect, useState } from "react";
import "./JobPostingPanel.scss";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";
import { deleteIndustries, getIndustries } from "../APIs/Repos";

function IndustryRow({ industryDataToPass, onDeleteButtonClickListener }) {
  const history = useHistory();
  return (
    <div className="industry__row">
      <div className="panel__main__row">
        <div className="row_data_entry_body">{industryDataToPass.name}</div>
        <div className="row_data_entry_body">
          <img
            style={{ width: '70px', height: '70px' }}
            className="industryImages"
            src={`https://api-amglobal.dsmeglobal.com/${industryDataToPass.industry_image}`}
            alt="Industry"
          />
        </div>
        <div className="row_data_entry_body">{industryDataToPass.short_description}</div>
        <div className="row_data_entry_body">{industryDataToPass.long_description}</div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Edit"
            onClick={() => {
              history.push("/dashboard/industry-edit", {
                state: { industryDataToEdit: industryDataToPass },
              });
            }}
          />
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Delete"
            onClick={() => onDeleteButtonClickListener(industryDataToPass)}
          />
        </div>
      </div>
    </div>
  );
}

export default function IndustriesPanel() {
  const [industryList, setIndustryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);
  const [deleteConfirmationPopupVisibility, setDeleteConfirmationPopupVisibility] = useState(false);

  // Pagination state
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1); // Added totalPages state

  useEffect(() => {
    window.scrollTo(0, 0);
    getIndustryApiCall();
  }, [page, pageSize]);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deleteIndustryHandler = (isDelete) => {
    if (isDelete) deleteIndustryApiCall(dataToManipulate);
    else setDeleteConfirmationPopupVisibility(false);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset page to 1 on page size change
  };

  const getIndustryApiCall = () => {
    setIsLoading(true);
    getIndustries(page, pageSize)
      .then((res) => {
        setIsLoading(false);
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          setIndustryList(res.data.data);
          setTotalPages(res.data.totalPages); // Assuming the API returns total pages
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteIndustryApiCall = (data) => {
    deleteIndustries(data._id)
      .then((res) => {
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          setIndustryList((prevList) => prevList.filter(
            (element) => element._id !== data._id
          ));
          setDeleteConfirmationPopupVisibility(false);
          getIndustryApiCall();
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="panel__container">
      <div className="panel__heading">
        Industries{" "}
        <span style={{ float: "right", width: "100px" }}>
          <Button
            variant="link"
            toPath="/dashboard/industry-edit"
            placeholder="Add New"
          />
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <div className="panel__main__heading__row">
          <div className="row_data_entry">Name</div>
          <div className="row_data_entry">Image</div>
          <div className="row_data_entry">Short Description</div>
          <div className="row_data_entry">Long Description</div>
          <div className="panel__main__heading__row__entry"></div>
        </div>
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : industryList.length > 0 ? (
          industryList.map((data) => (
            <IndustryRow
              key={data._id}
              industryDataToPass={data}
              onDeleteButtonClickListener={(data) => {
                setDataToManipulate(data);
                setDeleteConfirmationPopupVisibility(true);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
        )}
      </div>
      <div className="pagination-controls">
        <button
          className="btnn"
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </button>
        <span style={{ color: '#C2A147' }}>Page {page} of {totalPages}</span>
        <button
          className="btnn"
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= totalPages}
        >
          Next
        </button>
        <select
          className="btnn"
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          value={pageSize}
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </div>
      {deleteConfirmationPopupVisibility && (
        <DeleteConfirmationPopup
          onDialogCrossClickedListenerCallBack={deleteIndustryHandler}
        />
      )}
    </div>
  );
}
