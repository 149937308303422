import React, { useState, useEffect } from "react";
import "./JobPostingPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import { useHistory, useLocation } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import axios from "axios";
import isNullOrEmpty from "../Utils/TextUtils";
import { ERROR_CODES } from "../Constants";

export default function IndustryForm() {
  const history = useHistory();
  const location = useLocation();

  const [_id, setId] = useState(null);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] =
    useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [shortDescriptionErrorMessage, setShortDescriptionErrorMessage] =
    useState("");
  const [
    shortDescriptionErrorMessageVisibility,
    setShortDescriptionErrorMessageVisibility,
  ] = useState(false);
  const [longDescription, setLongDescription] = useState("");
  const [longDescriptionErrorMessage, setLongDescriptionErrorMessage] =
    useState("");
  const [
    longDescriptionErrorMessageVisibility,
    setLongDescriptionErrorMessageVisibility,
  ] = useState(false);
  const [image, setImage] = useState(null);
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [imageErrorMessageVisibility, setImageErrorMessageVisibility] =
    useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state && location.state.state.industryDataToEdit) {
      const { _id, name, short_description, long_description, industry_image } =
        location.state.state.industryDataToEdit;
      setId(_id);
      setName(name);
      setShortDescription(short_description);
      setLongDescription(long_description);
      setImagePreview(process.env.REACT_APP_API_URL +industry_image);
    }
  }, [location.state]);

  const addEditIndustryApiCall = async () => {
    const formData = new FormData();
    if (_id) {
      formData.append("_id", _id);
    }
    formData.append("name", name);
    formData.append("short_description", shortDescription);
    formData.append("long_description", longDescription);
    debugger
    if (image) formData.append("industry_image", image);
    else {
      const { industry_image } =
        location.state && location.state?.state.industryDataToEdit;
      formData.append("industry_image", industry_image);
    }
    if (isViewValid()) {
      setIsLoading(true);
      try {
        let response;
        if (_id) {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}industries/update-industries`,
            formData,
            {
              ["axios-retry"]: {
                retries: 5,
              },
            }
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}industries/add-industries`,
            formData,
            {
              ["axios-retry"]: {
                retries: 5,
              },
            }
          );
        }
        setIsLoading(false);
        if (response.data.error_code === ERROR_CODES.SUCCESS) {
          history.push("/dashboard/industries");
        } else {
          console.log(response.data.errorMessage);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
      }
    }
  };

  const onNameTextChangeListener = (event) => {
    setName(event.currentTarget.value);
    setNameErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setNameErrorMessageAndVisibility(true, "Enter Name");
      return false;
    } else {
      setNameErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setNameErrorMessageAndVisibility = (visibility, text) => {
    setNameErrorMessageVisibility(visibility);
    setNameErrorMessage(text);
  };

  const onShortDescriptionTextChangeListener = (event) => {
    const textValue = event.target.value;
    setShortDescription(textValue);
    setShortDescriptionErrorMessageAndVisibilityAndIsValid(textValue);
  };

  const setShortDescriptionErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    if (isNullOrEmpty(enteredText)) {
      setShortDescriptionErrorMessageAndVisibility(
        true,
        "Enter Short Description"
      );
      return false;
    } else {
      setShortDescriptionErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setShortDescriptionErrorMessageAndVisibility = (visibility, text) => {
    setShortDescriptionErrorMessageVisibility(visibility);
    setShortDescriptionErrorMessage(text);
  };

  const onLongDescriptionTextChangeListener = (event) => {
    const textValue = event.target.value;
    setLongDescription(textValue);
    setLongDescriptionErrorMessageAndVisibilityAndIsValid(textValue);
  };

  const setLongDescriptionErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    if (isNullOrEmpty(enteredText)) {
      setLongDescriptionErrorMessageAndVisibility(
        true,
        "Enter Long Description"
      );
      return false;
    } else {
      setLongDescriptionErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setLongDescriptionErrorMessageAndVisibility = (visibility, text) => {
    setLongDescriptionErrorMessageVisibility(visibility);
    setLongDescriptionErrorMessage(text);
  };

  const onImageFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setImageErrorMessageAndVisibility(false, "");
    } else {
      setImageErrorMessageAndVisibility(true, "Select an image file");
    }
  };

  const setImageErrorMessageAndVisibility = (visibility, text) => {
    setImageErrorMessageVisibility(visibility);
    setImageErrorMessage(text);
  };

  const isViewValid = () => {
    let viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    if (viewValid)
      viewValid =
        setShortDescriptionErrorMessageAndVisibilityAndIsValid(
          shortDescription
        );
    if (viewValid)
      viewValid =
        setLongDescriptionErrorMessageAndVisibilityAndIsValid(longDescription);
    if (viewValid) viewValid = !imageErrorMessageVisibility;
    return viewValid;
  };

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  return (
    <div className="panel__container">
      <div className="panel__heading">
        {_id ? "Edit Industry" : "Add Industry"}
      </div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Name
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Name"
                  ClassName="input__box"
                  type="text"
                  value={name}
                  onChange={onNameTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {nameErrorMessageVisibility ? nameErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Short Description
                </div>
              </div>
              <div className="company__details__row__input__box">
                <textarea
                style={{width:'100%',resize:'none'}}
                rows={5}

                  placeholder="Short Description"
                  className="text__area"
                  value={shortDescription}
                  onChange={onShortDescriptionTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {shortDescriptionErrorMessageVisibility
                    ? shortDescriptionErrorMessage
                    : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Long Description
                </div>
              </div>
              <div className="company__details__row__input__box">
                <textarea
                style={{width:'100%',resize:'none'}}
                // cols={20}
                rows={5}
                  placeholder="Long Description"
                  className="text__area"
                  value={longDescription}
                  onChange={onLongDescriptionTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {longDescriptionErrorMessageVisibility
                    ? longDescriptionErrorMessage
                    : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Image
                </div>
              </div>
              <div className="company__details__row__input__box">
                <input
                  type="file"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
                <div className="input__text__error__msg">
                  {imageErrorMessageVisibility ? imageErrorMessage : null}
                </div>
                {imagePreview && (
                  <div className="image__preview">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        padding: "10px",
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="company__details__row" style={{ marginTop: "3em" }}>
              <div
                className="company__details__row__attribute"
                style={{ width: "300px" }}
              >
                <div
                  className="company__details__row__attribute__heading"
                  style={{ color: "transparent" }}
                >
                  Placeholder
                </div>
              </div>
              <div
                className="company__details__row__input__box"
                style={{ width: "200px" }}
              >
                <ButtonComponent
                  variant="btn"
                  placeholder="Save"
                  onClick={addEditIndustryApiCall}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
