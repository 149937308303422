import React, { useEffect, useState } from "react";
import "./ApplicantsPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import { ERROR_CODES } from "../Constants";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { useHistory, useLocation } from "react-router-dom";
import { addJobType, editJobType } from "../APIs/Repos";

export default function JobTypeForm() {
  //#region Variables
  const isNullOrEmpty = (str) => !str || str.trim().length === 0;

  const history = useHistory();
  const location = useLocation();

  const [_id, setId] = useState(
    location.state ? location.state.state.jobTypeDataToEdit._id : 0
  );
  const [level, setLevel] = useState(
    location.state ? location.state.state.jobTypeDataToEdit.title : ""
  );
  const [levelErrorMessage, setLevelErrorMessage] = useState("");
  const [levelErrorMessageVisibility, setLevelErrorMessageVisibility] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  useEffect(() => {
    if (location.state) {
      const { _id } = location.state.state.jobTypeDataToEdit;
      setId(_id);
    }
  }, [location.state]);

  //#region API Calls

  const addJobTypeApiCall = () => {
    let data = { title: level };
    setIsLoading(true);
    addJobType(data)
      .then((res) => {
        setIsLoading(false);
        if (ERROR_CODES.ADD_EDIT_SUCCESS === res.data.error_code) {
          history.push("/dashboard/jobtype");
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const editJobTypeApiCall = () => {
    let data = {
      _id: _id,
      title: level,
    };
    setIsLoading(true);
    editJobType(data)
      .then((res) => {
        setIsLoading(false);
        if (ERROR_CODES.ADD_EDIT_SUCCESS === res.data.error_code) {
          history.push("/dashboard/jobtype");
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  //#endregion

  //#region Level Validation
  const onLevelTextChangeListener = (event) => {
    setLevel(event.currentTarget.value);
    setLevelErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setLevelErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    const isValid = !isNullOrEmpty(enteredText);
    setLevelErrorMessageAndVisibility(!isValid, isValid ? "" : "Enter Education");
    return isValid;
  };

  const setLevelErrorMessageAndVisibility = (visibility, text) => {
    setLevelErrorMessageVisibility(visibility);
    setLevelErrorMessage(text);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    return setLevelErrorMessageAndVisibilityAndIsValid(level);
  };
  //#endregion

  //#region Other Function
  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;
  //#endregion

  const handleSubmit = () => {
    if (isViewValid()) {
      if (_id === 0) {
        addJobTypeApiCall();
      } else {
        editJobTypeApiCall();
      }
    }
  };

  return (
    <div className="panel__container">
      <div className="panel__heading">Add/Edit Job Type</div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Title
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Job Type"
                  ClassName="input__box"
                  type="text"
                  value={level}
                  onChange={onLevelTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {levelErrorMessageVisibility ? levelErrorMessage : null}
                </div>
              </div>
            </div>

            <div className="company__details__row" style={{ marginTop: "3em" }}>
              <div
                className="company__details__row__attribute"
                style={{ width: "300px" }}
              >
                <div
                  className="company__details__row__attribute__heading"
                  style={{ color: "transparent" }}
                >
                  Save
                </div>
              </div>
              <div
                className="company__details__row__input__box"
                style={{ width: "200px" }}
              >
                <ButtonComponent
                  variant="btn"
                  placeholder="Save"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
