import React, { useState } from "react";
import "./JobPostingPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import UploadImage from "../components/UploadImage";
import { ERROR_CODES } from "../Constants";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { useHistory, useLocation } from "react-router-dom";
import { addEditTechnology } from "../APIs/Repos";
import isNullOrEmpty from "../Utils/TextUtils";
import { getBase64 } from "../Utils/TextUtils";

export default function TechnologyStackform() {
  //#region Variables

  const history = useHistory();
  const location = useLocation();

  const [id, setId] = useState(
    location.state ? location.state.state.technologyDataToEdit.id : 0
  );

  let [name, setName] = useState(
    location.state ? location.state.state.technologyDataToEdit.name : ""
  );
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] =
    useState(false);

  let [image, setImage] = useState(
    location.state ? location.state.state.technologyDataToEdit.image : ""
  );
  let [Image_base64, setImage_base64] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region APi Call

  const addEditTechnologyApiCall = () => {
    let data = {
      id: id,
      name: name,
      image: Image_base64 ? Image_base64 : image,
    };
    if (isViewValid()) {
      setIsLoading(true);
      addEditTechnology(data)
        .then((res) => {
          setIsLoading(false);
          if (ERROR_CODES.ADD_EDIT_SUCCESS == res.data.errorCode) {
            history.push("/dashboard/technology-stack");
          } else {
            console.log(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };
  //#endregion

  //#region Name Validation
  const onNameTextChangeListener = (event) => {
    setName((name = event.currentTarget.value));
    setNameErrorMessageAndVisibilityAndIsValid(name);
  };
  const setNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setNameErrorMessageAndVisibility(true, "Enter Name");
      return isViewValid;
    } else {
      setNameErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setNameErrorMessageAndVisibility = (visibility, text) => {
    setNameErrorMessageVisibility(visibility);
    setNameErrorMessage(text);
  };

  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    return viewValid;
  };
  //#endregion

  //#region other function

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const fileSelectedHandler = async (img) => {
    await getBase64(img, (result) => {
      setImage_base64(result);
      debugger;
    });
  };
  //#endregion
  return (
    <div className="panel__conatainer">
      <div className="panel__heading">Add Technologies</div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Name
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Name"
                  ClassName="input__box"
                  type="text"
                  value={name}
                  onChange={(event) => onNameTextChangeListener(event)}
                />
                <div className="input__text__error__msg">
                  {nameErrorMessageVisibility ? nameErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Image
                </div>
              </div>
              <div className="company__details__row__input__box">
                <UploadImage
                  onImageSelection={(img) => {
                    debugger;
                    if (img) {
                      fileSelectedHandler(img);
                    } else {
                      setImage("");
                      setImage_base64("");
                    }
                  }}
                  thumbnailImageToPass={image}
                />
              </div>
            </div>

            <div className="company__details__row" style={{ marginTop: "3em" }}>
              <div
                className="company__details__row__attribute"
                style={{ width: "300px" }}
              >
                <div
                  className="company__details__row__attribute__heading"
                  style={{ color: "transparent" }}
                >
                  Salary
                </div>
              </div>
              <div
                className="company__details__row__input__box"
                style={{ width: "200px" }}
              >
                <ButtonComponent
                  variant="btn"
                  placeholder="Save"
                  onClick={() => addEditTechnologyApiCall()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    //   </div>
  );
}
