import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Switch, Route, Link, useLocation, useHistory } from "react-router-dom";
import ButtonComponent from "../components/Button";
import ApplicantsDetailsPanel from "./ApplicantsDetailsPanel";
import { getCVs, getJobRelatedCvData } from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";
import "./ApplicantsPanel.scss";
import { RingLoader } from "react-spinners";

function ApplicantsRow({ data }) {
  return (
    <div className="applicant__row message-container" >
      <div className="panel__main__row">
        <Nav.Link
          className="panel__main__row__link"
          to={`/dashboard/applicants/details/?job_id=${data.job_id}`}
          as={Link}
        >
          <>
            <div className="panel__main__row__entry">{data.surname}</div>
            <div className="panel__main__row__entry">{data.email}</div>
            <div className="panel__main__row__entry">{data.phone}</div>
            <div className="panel__main__row__entry ">{data.message}</div>
          </>
        </Nav.Link>
        <div className="panel__main__row__entry">
          <ButtonComponent
            variant="download"
            placeholder="Download CV"
            downloadLink={process.env.REACT_APP_API_URL + data.resume}
          />
        </div>
      </div>
      <div className="full_message" style={{width: '100%'}}>
        <span style={{color : '#C2A147'}}>
        Messsage :
          </span> {data.message}
        </div>
    </div>
  );
}

export default function ApplicantsPanel() {
  const [cvList, setCvList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Entries per page
  const location = useLocation();
  const history = useHistory();
  const [job_id, setJob_id] = useState(null); // State to hold job_id

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get("page")) || 1;
    const job_id = query.get("job_id");

    setCurrentPage(page);
    setJob_id(job_id);
    getCVsApiCall(job_id);
  }, [location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getCVsApiCall = (job_id) => {
    setIsLoading(true);
    if (job_id) {
      getJobRelatedCvData(job_id)
        .then((res) => {
          if (res.data.error_code === ERROR_CODES.SUCCESS) {
            setCvList(res.data.result.applicants);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("An error occurred while fetching Cv data.", error);
        });
    } else {
      getCVs()
        .then((res) => {
          if (res.data.error_code === ERROR_CODES.SUCCESS) {
            setCvList(res.data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    history.push(
      `/dashboard/applicants?page=${pageNumber}${
        job_id ? `&job_id=${job_id}` : ""
      }`
    );
  };

  const paginatedCvList = cvList?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const totalPages = Math.ceil(cvList?.length / pageSize);

  return (
    <div className="panel__container">
      <Switch>
        <Route path="/dashboard/applicants" exact>
          <div className="panel__heading">Applicants</div>
          <div style={{ flex: 1 }}>
            <div className="panel__main__heading__row">
              <div className="panel__main__heading__row__entry">Name</div>
              <div className="panel__main__heading__row__entry">Email</div>
              <div className="panel__main__heading__row__entry">Phone</div>
              <div className="panel__main__heading__row__entry">Message</div>
              <div className="panel__main__heading__row__entry">CV</div>
            </div>
            {isLoading ? (
              <div className="loader-container">
                <RingLoader size={150} color={"rgb(194,161,71)"} />
              </div>
            ) : cvList.length === 0 ? (
              <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
            ) : (
              paginatedCvList.map((item) => (
                <ApplicantsRow key={item._id} data={item} />
              ))
            )}
            <div className="page__slider">
              <div className="slider">
                <Nav
                  variant="pills"
                  activeKey={`/dashboard/applicants?page=${currentPage}`}
                >
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage <= 1}
                      className="nav__link__horizontal"
                    >
                      Previous
                    </Nav.Link>
                  </Nav.Item>
                  {[...Array(totalPages)]?.map((_, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        to={`/dashboard/applicants?page=${index + 1}${
                          job_id ? `&job_id=${job_id}` : ""
                        }`}
                        as={Link}
                        className={`nav__link__horizontal ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages}
                      className="nav__link__horizontal"
                    >
                      Next
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          </div>
        </Route>
        <Route path="/dashboard/applicants/details">
          <div className="panel__heading">Applicants - Details</div>
          <div style={{ flex: 1 }}>
            <ApplicantsDetailsPanel jobId={job_id} />
          </div>
        </Route>
      </Switch>
    </div>
  );
}
