import React, { useState, useEffect } from "react";
import "./JobPostingPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import { useHistory, useLocation } from "react-router-dom";
import isNullOrEmpty from "../Utils/TextUtils";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";
import axios from "axios";

export default function CategoryForm() {
  const history = useHistory();
  const location = useLocation();

  const [_id, setId] = useState(null);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [descriptionErrorMessageVisibility, setDescriptionErrorMessageVisibility] = useState(false);
  const [image, setImage] = useState(null);
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [imageErrorMessageVisibility, setImageErrorMessageVisibility] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    
    if (location.state && location.state.state.categoryDataToEdit) {
      const { _id, name, description, logo } = location.state.state.categoryDataToEdit;
      setId(_id);
      setName(name);
      setDescription(description);
      setImagePreview(process.env.REACT_APP_API_URL +logo);
    }
  }, [location.state]);

  const addEditCategoryApiCall = async () => {
    const formData = new FormData();
    if (_id) {
      formData.append("_id", _id);
    }
    formData.append("name", name);
    formData.append("description", description);
    if (image) formData.append("logo", image);
    else {
     if (location.state && location.state.state.categoryDataToEdit) {
      const { logo } = location.state && location.state?.state.categoryDataToEdit;
      formData.append("logo", logo)};

     }
    if (isViewValid()) {
      setIsLoading(true);
      try {
        let response;
        if (_id) {
          // Edit Category
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}jobCategories/update-category`,
            formData,
            {
              ["axios-retry"]: {
                retries: 5,
              },
            }
          );
        } else {
          // Add Category
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}jobCategories/newCategory`,
            formData,
            {
              ["axios-retry"]: {
                retries: 5,
              },
            }
          );
        }
        setIsLoading(false);
        if (ERROR_CODES.ADD_EDIT_SUCCESS === response.data.error_code) {
          history.push("/dashboard/category");
        } else {
          console.log(response.data.errorMessage);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
      }
    }
  };

  const onNameTextChangeListener = (event) => {
    setName(event.currentTarget.value);
    setNameErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setNameErrorMessageAndVisibility(true, "Enter Category Name");
      return false;
    } else {
      setNameErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setNameErrorMessageAndVisibility = (visibility, text) => {
    setNameErrorMessageVisibility(visibility);
    setNameErrorMessage(text);
  };

  const onDescriptionTextChangeListener = (event) => {
    setDescription(event.currentTarget.value);
    setDescriptionErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setDescriptionErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setDescriptionErrorMessageAndVisibility(true, "Enter Category Description");
      return false;
    } else {
      setDescriptionErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setDescriptionErrorMessageAndVisibility = (visibility, text) => {
    setDescriptionErrorMessageVisibility(visibility);
    setDescriptionErrorMessage(text);
  };

  const onImageFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setImageErrorMessageAndVisibility(false, "");
    } else {
      setImage(null);
      setImagePreview("");
      setImageErrorMessageAndVisibility(true, "Select an image file");
    }
  };
  
  

  const setImageErrorMessageAndVisibility = (visibility, text) => {
    setImageErrorMessageVisibility(visibility);
    setImageErrorMessage(text);
  };

  const isViewValid = () => {
    let viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    if (viewValid) viewValid = setDescriptionErrorMessageAndVisibilityAndIsValid(description);
    if (viewValid) viewValid = image || location.state?.state.categoryDataToEdit.logo  !== null && !imageErrorMessageVisibility;
    return viewValid;
  };
  

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  return (
    <div className="panel__container">
      <div className="panel__heading">{_id ? "Edit Category" : "Add Category"}</div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Name</div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Category Name"
                  ClassName="input__box"
                  type="text"
                  value={name}
                  onChange={onNameTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {nameErrorMessageVisibility ? nameErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Description</div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Category Description"
                  ClassName="input__box"
                  type="text"
                  value={description}
                  onChange={onDescriptionTextChangeListener}
                />
                <div className="input__text__error__msg">
                  {descriptionErrorMessageVisibility ? descriptionErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">Logo</div>
              </div>
              <div className="company__details__row__input__box">
                <input
                  type="file"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
                <div className="input__text__error__msg">
                  {imageErrorMessageVisibility ? imageErrorMessage : null}
                </div>
                {imagePreview && (
                  <div className="image__preview">
                    <img src={imagePreview} alt="Preview" style={{padding:'10px', width: "100px", height: "100px", objectFit: "cover" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="company__details__row" style={{ marginTop: "3em" }}>
              <div className="company__details__row__attribute" style={{ width: "300px" }}>
                <div className="company__details__row__attribute__heading" style={{ color: "transparent" }}>
                  Placeholder
                </div>
              </div>
              <div className="company__details__row__input__box" style={{ width: "200px" }}>
                <ButtonComponent
                  variant="btn"
                  placeholder="Save"
                  onClick={addEditCategoryApiCall}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
