import React, { useEffect, useState } from "react";
import "./ApplicantsPanel.scss";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";
import { deleteJobType, getJobType } from "../APIs/Repos";

function JobTypeRow({ jobTypeDataToPass, onDeleteButtonClickListener }) {
  const history = useHistory();

  return (
    <div className="jobtype__row">
      <div className="panel__main__row">
        <div className="row_data_entry_body">
          {jobTypeDataToPass.title}
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Edit"
            onClick={() => {
              history.push("/dashboard/jobtype-edit", {
                state: { jobTypeDataToEdit: jobTypeDataToPass },
              });
            }}
          />
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Delete"
            onClick={() => onDeleteButtonClickListener(jobTypeDataToPass)}
          />
        </div>
      </div>
    </div>
  );
}

export default function JobTypePanel() {
  const [jobTypeList, setJobTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);
  const [deleteConfirmationPopupVisibility, setDeleteConfirmationPopupVisibility] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getJobTypesApiCall(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deleteJobTypeHandler = (isDelete) => {
    if (isDelete) deleteJobTypeApiCall(dataToManipulate);
    else setDeleteConfirmationPopupVisibility(false);
  };

  const getJobTypesApiCall = (page, size) => {
    setIsLoading(true);
    getJobType(page, size)
      .then((res) => {
        setIsLoading(false);
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          setJobTypeList(res.data.data);
          setTotalPages(res.data.totalPages); // Assuming the API returns total pages
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteJobTypeApiCall = (data) => {
    deleteJobType(data._id)
      .then((res) => {
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          const afterRemove = jobTypeList.filter(
            (element) => element._id !== data._id
          );
          setJobTypeList(afterRemove);
          setDeleteConfirmationPopupVisibility(false);
          getJobTypesApiCall(currentPage, pageSize);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="panel__container">
      <div className="panel__heading">
        Job Types{" "}
        <span style={{ float: "right", width: "100px" }}>
          <Button
            variant="link"
            toPath="/dashboard/jobtype-edit"
            placeholder="Add New"
          />
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <div className="panel__main__heading__row">
          <div className="row_data_entry">Name</div>
        </div>
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : jobTypeList && jobTypeList.length > 0 ? (
          jobTypeList.map((data) => (
            <JobTypeRow
              key={data._id}
              jobTypeDataToPass={data}
              onDeleteButtonClickListener={(data) => {
                setDataToManipulate(data);
                setDeleteConfirmationPopupVisibility(true);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
        )}
      </div>
      {deleteConfirmationPopupVisibility && (
        <DeleteConfirmationPopup
          onDialogCrossClickedListenerCallBack={deleteJobTypeHandler}
        />
      )}
      <div className="pagination">
        {currentPage > 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="simple-button"
          >
            Previous
          </button>
        )}
        <span>
          Page {currentPage} of {totalPages}
        </span>
        {currentPage < totalPages && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="simple-button"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
