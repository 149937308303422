import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
import "./ContactUs.scss";
import { getSubscribersApiCall } from "../APIs/Repos";

const Subscriber = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const contactsPerPage = 10; // Adjust as needed

  const getSubscribers = () => {
    setLoading(true);
    getSubscribersApiCall(currentPage, contactsPerPage)
      .then((res) => {
        if (res.data && res.data.data) {
          // Filter subscribers to show only those that have an email
          const filteredSubscribers = res.data.data.filter((contact) => contact.email);
          setSubscribers(filteredSubscribers);
          setTotalPages(res.data.totalPages);
          setLoading(false);
        } else {
          console.log("Error fetching subscriber data: No data received");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscriber data:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSubscribers();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container">
      <div className="panel__heading">Subscribers</div>
      {loading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th style={{textAlign:'left'}}>Email</th>
              </tr>
            </thead>
            <tbody>
              {subscribers.length > 0 ? (
                subscribers.map((subscriber) => (
                  <tr key={subscriber.id}>
                    <td style={{ fontSize: "12px",textAlign:'left' }}>{subscriber.email}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="1">No subscribers available</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="page__slider">
            <div className="slider">
              <nav>
                <ul className="pagination">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage <= 1}
                    >
                      Previous
                    </button>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Subscriber;
