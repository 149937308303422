import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./JobPostingPanel.scss";
import userPic from "../assets/userPic.png"; // Placeholder image
import { getApplicantDataById } from "../APIs/Repos"; // Make sure this function exists and works
import { ERROR_CODES } from "../Constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function ApplicantsDetailsPanel() {
  const [applicantData, setApplicantData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const job_id = query.get("job_id");

    if (job_id) {
      getApplicantDataById(job_id)
        .then((res) => {
          if (res.data.error_code === ERROR_CODES.SUCCESS) {
            setApplicantData(res.data.result);
          } else {
            setError(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setError("An error occurred while fetching applicant data.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setError("Job ID not found in URL.");
    }
  }, [location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!applicantData) {
    return <div>No applicant data found</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Format date as YYYY-MM-DD or use any format you prefer
    return date.toISOString().split("T")[0];
  };
  return (
    <div className="company__details__content">
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Category
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.category.name}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Title
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.job_title}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Type
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.job_type.title}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Skills
          </div>
        </div>
        {applicantData.skill_id?.map((items, index) => (
          <div key={index} className="company__details__row__value_array">
            {items.title}
          </div>
        ))}
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Education
          </div>
        </div>
        {applicantData.education_id?.map((items, index) => (
          <div key={index} className="company__details__row__value_array">
            {items.study}
          </div>
        ))}
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Priority
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.priority.title}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Location
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.address}
        </div>
      </div>
      <div className="company__details__row__textarea">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Experience
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.experience}
        </div>
      </div>
      <div className="company__details__row__textarea">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Description
          </div>
        </div>
        <div
          className="company__details__row__value"
          dangerouslySetInnerHTML={{ __html: applicantData.job_description }}
        />
      </div>

      <div className="company__details__row__textarea">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Requirment
          </div>
        </div>
        <div
          className="company__details__row__value"
          dangerouslySetInnerHTML={{ __html: applicantData.job_requirement }}
        />
      </div>
      <div className="company__details__row__textarea">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Company Detail
          </div>
        </div>
        <div
        style={{color : 'black'}}
          className="company__details__row__value"
          dangerouslySetInnerHTML={{ __html: applicantData.about_company }}
        />
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Working Hours
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.working_hours}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Company Logo
          </div>
        </div>
        <div
          className="company__details__row__value"
          style={{ maxWidth: "200px" }}
        >
          <img
            src={`https://api-amglobal.dsmeglobal.com/${applicantData.poster_image}`}
            alt="uploaded-applicant-img"
            style={{ width: "100px" }}
          />
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Weekly Offs
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.weekly_off}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Opening Date
          </div>
        </div>
        <div className="company__details__row__value">
          {formatDate(applicantData.start_date)}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Closing Date
          </div>
        </div>
        <div className="company__details__row__value">
          {formatDate(applicantData.end_date)}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Hiring Manager Name
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.hiring_manager_name}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Manager Picture
          </div>
        </div>
        <div
          className="company__details__row__value"
          style={{ maxWidth: "300px" }}
        >
          <img
            src={`https://api-amglobal.dsmeglobal.com/${applicantData.manager_image}`}
            alt="uploaded-applicant-img"
            style={{ width: "100px" }}
          />
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Manager Email
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.manager_email}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Manager Phone
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.manager_phone}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Manger WhatsApp
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.whatsapp_no}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Job Manager LinkedIn
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.manager_linkedin}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            No. of Opening
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.opening_no}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Min Salary
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.min_salary}
        </div>
      </div>
      <div className="company__details__row">
        <div className="company__details__row__attribute">
          <div className="company__details__row__attribute__heading">
            Max Salary
          </div>
        </div>
        <div className="company__details__row__value">
          {applicantData.max_salary}
        </div>
      </div>
    </div>
  );
}
