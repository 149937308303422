import React, { useState, useEffect } from "react";

// A simple spinner component
const Spinner = () => (
  <div className="spinner">
    <div className="spinner__circle"></div>
    <style jsx>{`
      .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px; /* Adjusted for better visibility */
        height: 50px; /* Adjusted for better visibility */
      }
      .spinner__circle {
        width: 50px; /* Adjusted for better visibility */
        height: 50px; /* Adjusted for better visibility */
        border: 4px solid #ddd;
        border-top: 4px solid #333;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

export default function DeleteConfirmationPopup({
  onDialogCrossClickedListenerCallBack,
  isDeleting // Receive the isDeleting state
}) {
  const [loading, setLoading] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [message, setMessage] = useState("Do you really want to delete this?");

  const handleDeleteClick = () => {
    setButtonsVisible(false);
    setLoading(true);
    setMessage("Processing...");
    
    // Show "Waiting..." after 3 seconds
    const waitingTimeout = setTimeout(() => {
      setMessage("Waiting...");
    }, 2000); // 3 seconds delay

    // Simulate the delete process
    setTimeout(() => {
      clearTimeout(waitingTimeout); // Clear the timeout if it's no longer needed
      setLoading(false);
      setMessage(""); // Clear the message
      onDialogCrossClickedListenerCallBack(true);
    }, 3000); // Total time for processing (e.g., 5 seconds)
  };

  useEffect(() => {
    // Prevent scrolling on the body when the popup is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling when the popup is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="popup">
      <div className="popup__form">
        <div className="popup__form__heading">
          {message || 'Waiting...'}
        </div>
        <div className="popup__form__row" style={{ justifyContent: "center" }}>
          {loading ? (
            <Spinner /> // Display spinner while loading
          ) : (
            <>
              {buttonsVisible && (
                <>
                  <button
                    onClick={handleDeleteClick}
                    className="popup__form__button"
                    style={{ marginRight: "1em", marginTop: "1em" }}
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => onDialogCrossClickedListenerCallBack(false)}
                    className="popup__form__button"
                    style={{
                      background: "#242424",
                      marginTop: "1em",
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
