import React, { useState, useEffect } from "react";
import "./ApplicantsPanel.scss";
import { Nav } from "react-bootstrap";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import Button from "../components/Button";
import { getTrainingDirection, deleteTrainingDirection } from "../APIs/Repos";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";

function ApplicantsRow({ trainingDataToPass, onDeleteButtonClickListener }) {
  const history = useHistory();
  return (
    <div className="applicant__row">
      <div className="panel__main__row">
        <div className="panel__main__row__entry">{trainingDataToPass.name}</div>
        <div className="panel__main__row__entry">
          {trainingDataToPass.isfeatured == true ? "Yes" : "No"}
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          {" "}
          <Button
            // variant="link"
            // toPath="/dashboard/training-direction-edit"
            // placeholder="Edit"
            variant="btn"
            placeholder="Edit"
            onClick={() => {
              history.push("/dashboard/training-direction-edit", {
                state: { trainingDataToEdit: trainingDataToPass },
              });
            }}
          />
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Delete"
            onClick={() => onDeleteButtonClickListener(trainingDataToPass)}
          />
        </div>
      </div>
    </div>
  );
}

export default function TrainingDirection() {
  //#region Variables
  const [trainingDirectionList, setTrainingDirectionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);

  const [
    deleteConfirmationPopupVisibility,
    setDeleteConfirmationPopupVisibility,
  ] = useState(false);

  //#endregion

  //#region other functions
  useEffect(() => {
    getTrainingDirectionApiCall();
  }, []);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deletePackageHandler = (isDelete) => {
    if (isDelete) deleteTrainingDirectionApiCall(dataToManipulate);
    else setDeleteConfirmationPopupVisibility(false);
  };
  //#endregion

  //#region  API Calls
  const getTrainingDirectionApiCall = () => {
    setIsLoading(true);
    getTrainingDirection()
      .then((res) => {
        setIsLoading(false);
        if (ERROR_CODES.SUCCESS == res.data.errorCode) {
          setTrainingDirectionList(res.data.data);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteTrainingDirectionApiCall = (data) => {
    deleteTrainingDirection(data.id)
      .then((res) => {
        if (ERROR_CODES.SUCCESS == res.data.errorCode) {
          var afterRemove = trainingDirectionList.filter(
            (element) => element.id != data.id
          );
          setTrainingDirectionList(afterRemove);
          setDeleteConfirmationPopupVisibility(false);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion
  return (
    <div className="panel__conatainer">
      <div className="panel__heading">
        Training Direction{" "}
        <span style={{ float: "right", width: "100px" }}>
          <Button
            variant="link"
            toPath="/dashboard/training-direction-edit"
            placeholder="Add New"
          />
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <div className="panel__main__heading__row">
          <div className="panel__main__heading__row__entry">Name</div>
          <div className="panel__main__heading__row__entry">Featured</div>
        </div>
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : trainingDirectionList != null &&
          trainingDirectionList.length > 0 ? (
          trainingDirectionList.map((data) => (
            <ApplicantsRow
              trainingDataToPass={data}
              onDeleteButtonClickListener={(data) => {
                setDataToManipulate(data);
                setDeleteConfirmationPopupVisibility(true);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
        )}
      </div>
      {
        //#region Delete Confirmation Popup
        deleteConfirmationPopupVisibility ? (
          <DeleteConfirmationPopup
            onDialogCrossClickedListenerCallBack={deletePackageHandler}
          />
        ) : null
        //#endregion
      }
    </div>
  );
}
