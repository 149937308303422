import React, { useState, useEffect } from "react";
import "./ApplicantsPanel.scss";
import { Nav } from "react-bootstrap";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import Button from "../components/Button";
import userPic from "../assets/userPic.png";
import { getPortfolio, deletePortfolio } from "../APIs/Repos";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";

function ApplicantsRow({ portfolioDataToPass, onDeleteButtonClickListener }) {
  const history = useHistory();
  return (
    <div className="applicant__row">
      <div className="panel__main__row">
        <div className="panel__main__row__entry">
          {portfolioDataToPass.name}
        </div>
        <div className="panel__main__row__entry">technology</div>
        <div className="panel__main__row__entry">Services</div>
        <div className="panel__main__row__entry">
          <div className="main__content__table__list__entry__message__heading">
            View
          </div>
          <div
            className="main__content__table__list__entry__message"
            dangerouslySetInnerHTML={{
              __html: portfolioDataToPass.description,
            }}
          ></div>
        </div>
        {/* <div className="panel__main__row__entry">description</div> */}
        <div className="panel__main__row__entry">detail</div>
        <div className="panel__main__row__entry">
          {portfolioDataToPass.thumnail ? (
            <img
              src={process.env.REACT_APP_API_URL + portfolioDataToPass.thumnail}
            />
          ) : null}
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Detail"
            onClick={() => {
              history.push("/dashboard/project-portfolio-detail", {
                state: {
                  portfolioDetailToPass: portfolioDataToPass,
                },
              });
            }}
          />
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          {" "}
          <Button
            variant="btn"
            placeholder="Edit"
            onClick={() => {
              history.push("/dashboard/project-portfolio-edit", {
                state: { portfolioDataToEdit: portfolioDataToPass },
              });
            }}
          />
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Delete"
            onClick={() => onDeleteButtonClickListener(portfolioDataToPass)}
          />
        </div>
      </div>
    </div>
  );
}

export default function Portfolio() {
  //#region Variables
  const [portfolioList, setPortfolioList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);

  const [
    deleteConfirmationPopupVisibility,
    setDeleteConfirmationPopupVisibility,
  ] = useState(false);
  //#endregion

  //#region other functions
  useEffect(() => {
    getPortfolioApiCall();
  }, []);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deletePackageHandler = (isDelete) => {
    if (isDelete) deletePortfolioApiCall(dataToManipulate);
    else setDeleteConfirmationPopupVisibility(false);
  };
  //#endregion

  //#region API Calls
  const getPortfolioApiCall = () => {
    setIsLoading(true);
    getPortfolio()
      .then((res) => {
        setIsLoading(false);
        if (res.data.errorCode == ERROR_CODES.SUCCESS) {
          setPortfolioList(res.data.data);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deletePortfolioApiCall = (data) => {
    deletePortfolio(data.id)
      .then((res) => {
        if (ERROR_CODES.SUCCESS == res.data.errorCode) {
          var afterRemove = portfolioList.filter(
            (element) => element.id != data.id
          );
          setPortfolioList(afterRemove);
          setDeleteConfirmationPopupVisibility(false);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion
  return (
    <div className="panel__conatainer">
      <div className="panel__heading">
        Project Portfolio{" "}
        <span style={{ float: "right", width: "100px" }}>
          <Button
            variant="link"
            toPath="/dashboard/project-portfolio-edit"
            placeholder="Add New"
          />
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <div className="panel__main__heading__row">
          <div className="panel__main__heading__row__entry">Name</div>
          <div className="panel__main__heading__row__entry">Technology</div>
          <div className="panel__main__heading__row__entry">Services</div>
          <div className="panel__main__heading__row__entry">Description</div>
          <div className="panel__main__heading__row__entry">Detail</div>
          <div className="panel__main__heading__row__entry">Thumbnail</div>
        </div>
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : portfolioList != null && portfolioList.length > 0 ? (
          portfolioList.map((data) => (
            <ApplicantsRow
              portfolioDataToPass={data}
              onDeleteButtonClickListener={(data) => {
                setDataToManipulate(data);
                setDeleteConfirmationPopupVisibility(true);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
        )}
      </div>
      {
        //#region Delete Confirmation Popup
        deleteConfirmationPopupVisibility ? (
          <DeleteConfirmationPopup
            onDialogCrossClickedListenerCallBack={deletePackageHandler}
          />
        ) : null
        //#endregion
      }
    </div>
  );
}
