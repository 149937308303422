import React, { useEffect, useState } from "react";
import "./ApplicantsPanel.scss";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";
import { deleteTestimonials, getTestimonials } from "../APIs/Repos";

function TestimonialRow({ testimonialDataToPass, onDeleteButtonClickListener }) {
  const history = useHistory();
  return (
    <div className="testimonial__row">
      <div className="panel__main__row">
        <div className="panel__main__row__entry">
          <img
            className="testimonialImages"
            src={`https://api-amglobal.dsmeglobal.com/${testimonialDataToPass.testimonial_image}`}
            alt="Testimonial"
          />
        </div>
        <div className="panel__main__row__entry">{testimonialDataToPass.name}</div>
        <div className="panel__main__row__entry">{testimonialDataToPass.designation}</div>
        <div className="panel__main__row__entry">{testimonialDataToPass.company}</div>
        <div className="panel__main__row__entry">{testimonialDataToPass.rating}</div>
        <div className="panel__main__row__entry" style={{ width: "100px" }}>
          <Button
            variant="btn"
            placeholder="Edit"
            onClick={() => {
              history.push("/dashboard/testimonial-edit", {
                state: { testimonialDataToEdit: testimonialDataToPass },
              });
            }}
          />
        </div>
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Delete"
            onClick={() => onDeleteButtonClickListener(testimonialDataToPass)}
          />
        </div>
      </div>
    </div>
  );
}

export default function TestimonialsPanel() {

  //#region Variables
  const [testimonialList, setTestimonialList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);
  const [deleteConfirmationPopupVisibility, setDeleteConfirmationPopupVisibility] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //#endregion

  //#region other functions
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);

    // Fetch the testimonial list when the page or pageSize changes
    getTestimonialApiCall();
  }, [page, pageSize]);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deleteTestimonialHandler = (isDelete) => {
    if (isDelete) deleteTestimonialApiCall(dataToManipulate);
    else setDeleteConfirmationPopupVisibility(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };
  //#endregion

  //#region API Calls
  const getTestimonialApiCall = () => {
    setIsLoading(true);
    getTestimonials(page, pageSize)
      .then((res) => {
        setIsLoading(false);
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          setTestimonialList(res.data.data);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteTestimonialApiCall = (data) => {
    deleteTestimonials(data._id)
      .then((res) => {
        debugger
        if (ERROR_CODES.SUCCESS === res.data.error_code) {
          const afterRemove = testimonialList.filter(
            (element) => element.id !== data.id
          );
          setTestimonialList(afterRemove);
          setDeleteConfirmationPopupVisibility(false);
          getTestimonialApiCall();
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion

  return (
    <div className="panel__container">
      <div className="panel__heading">
        Testimonials{" "}
        <span style={{ float: "right", width: "100px" }}>
          <Button
            variant="link"
            toPath="/dashboard/testimonial-edit"
            placeholder="Add New"
          />
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <div className="panel__main__heading__row">
          <div className="panel__main__heading__row__entry">Image</div>
          <div className="panel__main__heading__row__entry">Name</div>
          <div className="panel__main__heading__row__entry">Designation</div>
          <div className="panel__main__heading__row__entry">Company</div>
          <div className="panel__main__heading__row__entry">Rating</div>
          <div className="panel__main__heading__row__entry"></div>
        </div>
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : testimonialList.length > 0 ? (
          testimonialList.map((data) => (
            <TestimonialRow
              key={data.id}
              testimonialDataToPass={data}
              onDeleteButtonClickListener={(data) => {
                setDataToManipulate(data);
                setDeleteConfirmationPopupVisibility(true);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
        )}
      </div>
      <div className="pagination-controls">
        <button
          className="btnn"
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </button>
        <span style={{ color: '#C2A147' }}>Page {page}</span>
        <button
          className="btnn"
          onClick={() => handlePageChange(page + 1)}
        >
          Next
        </button>
        <select
          className="btnn"
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          value={pageSize}
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </div>
      {deleteConfirmationPopupVisibility && (
        <DeleteConfirmationPopup
          onDialogCrossClickedListenerCallBack={deleteTestimonialHandler}
        />
      )}
    </div>
  );
}
