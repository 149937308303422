import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Nav,
  Dropdown,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { addEditJobStatus, getApplicantDataById } from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";

function Status({ value }) {
  if (value === 1) {
    return (
      <Alert
        key="live"
        className="status status-live"
        style={{
          color: "#444444",
          height: 36,
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: "#09C956",
            borderRadius: 10,
            marginRight: 10,
          }}
        ></div>{" "}
        Live
      </Alert>
    );
  } else if (value === 2) {
    return (
      <Alert
        key="hold"
        className="status status-hold"
        style={{
          color: "#444444",
          height: 36,
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: "#ffae0c",
            borderRadius: 10,
            marginRight: 10,
          }}
        ></div>
        Hold
      </Alert>
    );
  } else if (value === 3) {
    return (
      <Alert
        key="closed"
        className="status status-closed"
        style={{
          color: "#444444",
          height: 36,
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: "#ff4d4d",
            borderRadius: 10,
            marginRight: 10,
          }}
        ></div>
        Closed
      </Alert>
    );
  }
}

export default function PostingRows({
  id,
  jobsDataToPass,
  onDeleteButtonClickListener,
}) {
  const history = useHistory();
  const [selected, setSelected] = useState(
    jobsDataToPass.status === 1 ? 1 : jobsDataToPass.status === 3 ? 3 : 2
  );
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  const addEditJobStatusApiCall = (value) => {
    setIsLoadingStatus(true);
    let data = {
      _id: jobsDataToPass._id,
      status: value,
    };
    addEditJobStatus(data)
      .then((res) => {
        if (res.data.error_code === ERROR_CODES.SUCCESS) {
          setSelected(value);
        } else {
          alert(res.data.errorMessage);
        }
      })
      .catch(() => {
        alert("An error occurred while updating the status.");
      })
      .finally(() => {
        setIsLoadingStatus(false);
      });
  };

  const job_id = jobsDataToPass._id;
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [applicantData, setApplicantData] = useState(null);

  useEffect(() => {
    if (job_id) {
      getApplicantDataById(job_id)
        .then((res) => {
          if (res.data.error_code === ERROR_CODES.SUCCESS) {
            setApplicantData(res.data.result);
          } else {
            setError(res.data.errorMessage);
          }
        })
        .catch(() => {
          setError("An error occurred while fetching applicant data.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [job_id]);

  function timeAgo(date) {
    const now = new Date();
    const then = new Date(date);
    const seconds = Math.floor((now - then) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
  
    if (minutes < 1) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }
  

  function handleChangeLive() {
    addEditJobStatusApiCall(1);
  }

  function handleChangeHold() {
    addEditJobStatusApiCall(2);
  }

  function handleChangeClosed() {
    addEditJobStatusApiCall(3);
  }

  return (
    <Nav.Link className="nav__link__padding">
      <Row className="posting__table__row data__row">
        <Col className="table__data__col">
          <Row className="table__data__row">
            <Nav.Link
              to={`/dashboard/applicants/details/?job_id=${id}`}
              as={Link}
              className="title table__data"
            >
              <Col style={{ width: "120px", textWrap: "wrap" }}>
                {jobsDataToPass.job_title}
              </Col>
            </Nav.Link>
            <Nav.Link
              to={`/dashboard/applicants/?job_id=${id}`}
              as={Link}
              className="applicants table__data"
            >
              <Col>{jobsDataToPass.applicant_count}</Col>
            </Nav.Link>
            <Nav.Link
              to="#"
              as={Link}
              className="date-created table__data"
            >
              <Col>{timeAgo(jobsDataToPass.created_on)}</Col>
            </Nav.Link>
            <Col className="status__col">
              <Dropdown as={ButtonGroup}>
                {isLoadingStatus ? (
                  <Button
                    variant="light"
                    disabled
                    style={{
                      backgroundColor: "#eeeeee",
                      borderColor: "#eeeeee",
                      height: 36,
                    }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{ marginRight: "5px" }}
                    />
                  </Button>
                ) : (
                  <Status value={selected} />
                )}
                <Dropdown.Toggle
                  split
                  variant="dark"
                  id="dropdown-custom-2"
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "#fff",
                    color: "#444444",
                    height: 36,
                  }}
                />
                <Dropdown.Menu className="super-colors">
                  <Dropdown.Item
                    onClick={handleChangeLive}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: "#09C956",
                        borderRadius: 10,
                        marginRight: 10,
                      }}
                    ></div>
                    Live
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleChangeHold}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: "#ffae0c",
                        borderRadius: 10,
                        marginRight: 10,
                      }}
                    ></div>
                    Hold
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleChangeClosed}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: "#ff4d4d",
                        borderRadius: 10,
                        marginRight: 10,
                      }}
                    ></div>
                    Closed
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col
              className="edits"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="outline-primary"
                style={{
                  border: "none",
                  padding: "0em",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="data__row__btn__reverse"
                onClick={() =>
                  history.push("/dashboard/job-posting", {
                    state: {
                      jobsDataToEdit: applicantData,
                    },
                  })
                }
              >
                Edit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Nav.Link>
  );
}
