import React, { useState, useEffect } from "react";
import "./ApplicantsPanel.scss";
import { Nav } from "react-bootstrap";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import Button from "../components/Button";
import userPic from "../assets/userPic.png";
import { getQuotations, deleteQuotations } from "../APIs/Repos";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";

function ApplicantsRow({ quotationDataToPass, onDeleteButtonClickListener }) {
  return (
    <div className="applicant__row">
      <div className="panel__main__row">
        <div className="panel__main__row__entry">
          {quotationDataToPass.name}
        </div>
        <div className="panel__main__row__entry">
          {quotationDataToPass.domain}
        </div>
        <div className="panel__main__row__entry">
          {quotationDataToPass.phone}
        </div>
        <div className="panel__main__row__entry">
          {quotationDataToPass.email}
        </div>
        <div className="panel__main__row__entry">
          <div className="main__content__table__list__entry__message__heading">
            View
          </div>
          <div
            className="main__content__table__list__entry__message"
            dangerouslySetInnerHTML={{ __html: quotationDataToPass.detail }}
          >
            {/* description */}
          </div>
        </div>
        {/* <div className="panel__main__row__entry">description</div> */}
        <div className="panel__main__row__entry" style={{ width: "85px" }}>
          <Button
            variant="btn"
            placeholder="Delete"
            onClick={() => onDeleteButtonClickListener(quotationDataToPass)}
          />
        </div>
      </div>
    </div>
  );
}

export default function Quotations() {
  //#region Variables
  const [quotationsList, setQuotationsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);

  const [
    deleteConfirmationPopupVisibility,
    setDeleteConfirmationPopupVisibility,
  ] = useState(false);
  //#endregion

  //#region other functions
  useEffect(() => {
    getQuotationsApiCall();
  }, []);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deletePackageHandler = (isDelete) => {
    if (isDelete) deleteQuotationsApiCall(dataToManipulate);
    else setDeleteConfirmationPopupVisibility(false);
  };
  //#endregion

  //#region API Calls
  const getQuotationsApiCall = () => {
    setIsLoading(true);
    getQuotations()
      .then((res) => {
        setIsLoading(false);
        if (res.data.errorCode == ERROR_CODES.SUCCESS) {
          setQuotationsList(res.data.data);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteQuotationsApiCall = (data) => {
    deleteQuotations(data.id)
      .then((res) => {
        if (ERROR_CODES.SUCCESS == res.data.errorCode) {
          var afterRemove = quotationsList.filter(
            (element) => element.id != data.id
          );
          setQuotationsList(afterRemove);
          setDeleteConfirmationPopupVisibility(false);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion
  return (
    <div className="panel__conatainer">
      <div className="panel__heading">
        Quotations{" "}
        <span style={{ float: "right", width: "100px" }}>
          {/* <Button
              variant="link"
              toPath="/dashboard/project-portfolio-edit"
              placeholder="Add New"
            /> */}
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <div className="panel__main__heading__row">
          <div className="panel__main__heading__row__entry">Project Name</div>
          <div className="panel__main__heading__row__entry">Domain</div>
          <div className="panel__main__heading__row__entry">Phone</div>
          <div className="panel__main__heading__row__entry">Email</div>
          <div className="panel__main__heading__row__entry">
            Project Details
          </div>
        </div>
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : quotationsList != null && quotationsList.length > 0 ? (
          quotationsList.map((data) => (
            <ApplicantsRow
              quotationDataToPass={data}
              onDeleteButtonClickListener={(data) => {
                setDataToManipulate(data);
                setDeleteConfirmationPopupVisibility(true);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginTop: "50px",
              color: "black",
            }}
          >
            Data Not Found
          </div>
        )}
        {
          //#region Delete Confirmation Popup
          deleteConfirmationPopupVisibility ? (
            <DeleteConfirmationPopup
              onDialogCrossClickedListenerCallBack={deletePackageHandler}
            />
          ) : null
          //#endregion
        }
      </div>
    </div>
  );
}
