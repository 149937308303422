import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row, Nav, Button, Pagination } from "react-bootstrap";
import "./JobListingPanel.scss";
import { Link, useHistory } from "react-router-dom";
import PostingRow from "../components/PostingRow";
import { getJobs, deleteJob } from "../APIs/Repos";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { ERROR_CODES } from "../Constants";

export default function JobListingPanel() {
  //#region Variables
  const [jobsList, setJobsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToManipulate, setDataToManipulate] = useState(null);
  const [
    deleteConfirmationPopupVisibility,
    setDeleteConfirmationPopupVisibility,
  ] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10; // Number of items per page
  //#endregion

  //#region other functions
  useEffect(() => {
    window.scrollTo(0, 0);
    getJobsApiCall(currentPage, pageSize);
  }, [currentPage]);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const deletePackageHandler = (isDelete) => {
    if (isDelete) deleteJobApiCall(dataToManipulate);
    else setDeleteConfirmationPopupVisibility(false);
  };
  //#endregion

  //#region API Calls
  const getJobsApiCall = (page, pageSize) => {
    setIsLoading(true);
    getJobs(page, pageSize)
      .then((res) => {
        setIsLoading(false);
        if (res.data.error_code == ERROR_CODES.SUCCESS) {
          setJobsList(res.data.data);
          setTotalPages(res.data.data.totalPages);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteJobApiCall = (data) => {
    deleteJob(data.id)
      .then((res) => {
        if (ERROR_CODES.SUCCESS == res.data.errorCode) {
          var afterRemove = jobsList.filter((element) => element.id != data.id);
          setJobsList(afterRemove);
          setDeleteConfirmationPopupVisibility(false);
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion

  // Pagination handlers
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="panel__conatainer">
      <div className="panel__heading">Job Listing</div>
      <div style={{ flex: 1 }}>
        <Col>
          <Row className="posting__table__row__heading">
            <Col className="title table__heading">Title</Col>
            <Col className="applicants table__heading">Applicants</Col>
            {/* <Col className="visitors table__heading">Visitors</Col> */}
            <Col className="date-created table__heading">Created</Col>
            <Col className="state table__heading">Status</Col>
            <Col className="state table__heading" style={{ color: "white" }}>
              Edit
            </Col>
            {/* <Col className="state table__heading" style={{ color: "white" }}>Duplicate</Col> */}
          </Row>
          {isLoading ? (
            <div className="loader-container">
              <RingLoader size={150} color={"rgb(194,161,71)"} />
            </div>
          ) : jobsList.length > 0 ? (
            jobsList.map((data) => (
              <PostingRow
                id={data._id}
                key={data._id}
                jobsDataToPass={data}
                onDeleteButtonClickListener={(data) => {
                  setDataToManipulate(data);
                  setDeleteConfirmationPopupVisibility(true);
                }}
              />
            ))
          ) : (
            <div
              style={{
                fontSize: "30px",
                textAlign: "center",
                marginTop: "50px",
                color: "black",
              }}
            >
              Data Not Found
            </div>
          )}

          {/* Pagination Controls */}
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
        </Col>
      </div>
      {deleteConfirmationPopupVisibility && (
        <DeleteConfirmationPopup
          onDialogCrossClickedListenerCallBack={deletePackageHandler}
        />
      )}
    </div>
  );
}
