import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
import "./ContactUs.scss";
import { fetchRequestProposals } from "../APIs/Repos";

const RequestProposals = () => {
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const proposalsPerPage = 10; // Adjust as needed

  const getProposals = () => {
    setLoading(true);
    fetchRequestProposals(currentPage, proposalsPerPage)
      .then((res) => {
        if (res.data && res.data.data) {
          setProposals(res.data.data);
          setTotalPages(res.data.totalPages);
          setLoading(false);
        } else {
          console.log("Error fetching proposal data: No data received");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching proposal data:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProposals();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container">
      <div className="panel__heading">Requested Proposals</div>
      {loading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th style={{fontSize :'12px'}}>First <br /> Name</th>
                <th style={{fontSize :'12px'}}>Last <br /> Name</th>
                <th style={{fontSize :'12px'}}>Business <br /> Email</th>
                <th style={{fontSize :'12px'}}>Phone <br /> Number</th>
                <th style={{fontSize :'12px'}}>Company <br /> Name</th>
                <th style={{fontSize :'12px'}}>Job <br /> Title</th>
                <th style={{fontSize :'12px'}}>Payroll <br /> Outsourcing</th>
                <th style={{fontSize :'12px'}}>Payroll <br /> Headcount</th>
                <th style={{fontSize :'12px'}}>Outsourcing <br /> Country</th>
              </tr>
            </thead>
            <tbody>
              {proposals?.length > 0 ? (
                proposals?.map((proposal) => (
                  <tr key={proposal.id} style={{fontSize :'15px'}}>
                    <td style={{fontSize :'12px'}}>{proposal.first_name}</td>
                    <td style={{fontSize :'12px'}}>{proposal.last_name}</td>
                    <td style={{fontSize :'12px'}}>{proposal.business_email}</td>
                    <td style={{fontSize :'12px'}}>{proposal.phone_no}</td>
                    <td style={{fontSize :'12px'}}>{proposal.company_name}</td>
                    <td style={{fontSize :'12px'}}>{proposal.your_title}</td>
                    <td style={{fontSize :'12px'}}>{proposal.payroll_outsourcing}</td>
                    <td style={{fontSize :'12px'}}>{proposal.payroll_headcount}</td>
                    <td style={{fontSize :'12px'}}>{proposal.payroll_services}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">No proposals available</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="page__slider">
            <div className="slider">
              <nav>
                <ul className="pagination">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage <= 1}
                    >
                      Previous
                    </button>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RequestProposals;
