import React, { useState, useEffect, isValidElement, useMemo } from "react";
import "./JobPostingPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import Select from "react-select";
import UploadImage from "../components/UploadImage";
import isNullOrEmpty from "../Utils/TextUtils";
import {
  addEditJob,
  getCategories,
  getEducation,
  getExperience,
  getJobSkill,
  getJobType,
  getSpecialism,
  getTrainingDirection,
  lookUpDataForJobPosting,
} from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";
import { useHistory, useLocation } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import TextArea from "../components/TextArea";
import { getBase64 } from "../Utils/TextUtils";
import moment from "moment";
import axios from "axios";

// const jobTypeOptions = [
//   { value: "full-time", label: "Full-Time" },
//   { value: "part-time", label: "Part-Time" },
//   { value: "contract", label: "Contract" },
//   { value: "temporary", label: "Temporary" },
//   { value: "internship", label: "Internship" },
// ];
const experiencesOption = [
  { value: "0-1", label: "Junior (0-1 years)" },
  { value: "1-3", label: "Mid Level (1-3 years)" },
  { value: "3-5", label: "Senior Level (3-5 years)" },
  { value: "5+", label: "Executive (5+ years)" },
];

export default function JobPostingPanel() {
  //#region Variables

  const history = useHistory();
  const location = useLocation();
  const isNullOrEmpty = (str) => !str || str?.trim()?.length === 0;
  const [duplicateJob, setDuplicateJob] = useState(
    location.state ? location.state.state.duplicateJob : false
  );
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoPreview, setCompanyLogoPreview] = useState(
    location.state && location.state.state.jobsDataToEdit.poster_image
      ? `https://api-amglobal.dsmeglobal.com/${location.state.state.jobsDataToEdit.poster_image}`
      : ""
  );
  const [companyLogoErrorMessage, setCompanyLogoErrorMessage] = useState("");
  const [
    companyLogoErrorMessageVisibility,
    setCompanyLogoErrorMessageVisibility,
  ] = useState(false);

  const [managerImage, setManagerImage] = useState(null);
  const [managerImagePreview, setManagerImagePreview] = useState(
    location.state && location.state.state.jobsDataToEdit.manager_image
      ? `https://api-amglobal.dsmeglobal.com/${location.state.state.jobsDataToEdit.manager_image}`
      : ""
  );
  const [managerImageErrorMessage, setManagerImageErrorMessage] = useState("");
  const [
    managerImageErrorMessageVisibility,
    setManagerImageErrorMessageVisibility,
  ] = useState(false);

  const [workingHours, setWorkingHours] = useState(
    location.state ? location.state.state.jobsDataToEdit.working_hours : null
  );
  const [workingHoursErrorMessage, setWorkingHoursErrorMessage] = useState("");
  const [
    workingHoursErrorMessageVisibility,
    setWorkingHoursErrorMessageVisibility,
  ] = useState(false);
  const [weeklyOffs, setWeeklyOffs] = useState(
    location.state ? location.state.state.jobsDataToEdit.weekly_off : null
  );
  const [weeklyOffsErrorMessage, setWeeklyOffsErrorMessage] = useState("");
  const [
    weeklyOffsErrorMessageVisibility,
    setWeeklyOffsErrorMessageVisibility,
  ] = useState(false);
  useState(false);
  const [hiringManager, setHiringManager] = useState(
    location.state
      ? location.state.state.jobsDataToEdit.hiring_manager_name
      : ""
  );
  const [hiringManagerErrorMessage, setHiringManagerErrorMessage] =
    useState("");
  const [
    hiringManagerErrorMessageVisibility,
    setHiringManagerErrorMessageVisibility,
  ] = useState(false);

  const [minSalary, setMinSalary] = useState(
    location.state ? location.state.state.jobsDataToEdit.min_salary : null
  );
  const [minSalaryErrorMessage, setMinSalaryErrorMessage] = useState("");
  const [minSalaryErrorMessageVisibility, setMinSalaryErrorMessageVisibility] =
    useState(false);
  const [maxSalary, setMaxSalary] = useState(
    location.state ? location.state.state.jobsDataToEdit.max_salary : null
  );
  const [maxSalaryErrorMessage, setMaxSalaryErrorMessage] = useState("");
  const [maxSalaryErrorMessageVisibility, setMaxSalaryErrorMessageVisibility] =
    useState(false);
  let [numberOfOpenings, setNumberOfOpenings] = useState(
    location.state ? location.state.state.jobsDataToEdit.opening_no : null
  );
  let [numberOfOpeningsErrorMessage, setNumberOfOpeningsErrorMessage] =
    useState("");
  let [
    numberOfOpeningsErrorMessageVisibility,
    setNumberOfOpeningsErrorMessageVisibility,
  ] = useState(false);

  // const [id, setId] = useState(
  //   location.state ? location.state.state.jobsDataToEdit.id : 0
  // );

  let [title, setTitle] = useState(
    location.state ? location.state.state.jobsDataToEdit.job_title : ""
  );
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [titleErrorMessageVisibility, setTitleErrorMessageVisibility] =
    useState(false);

  let [experience, setExperience] = useState(
    location.state ? location.state.state.jobsDataToEdit.experience : ""
  );
  const [experienceErrorMessage, setExperienceErrorMessage] = useState("");
  const [
    experienceErrorMessageVisibility,
    setExperienceErrorMessageVisibility,
  ] = useState(false);

  let [jobDescription, setJobDescription] = useState(
    location.state ? location.state.state.jobsDataToEdit.job_description : ""
  );
  const [jobDescriptionErrorMessage, setJobDescriptionErrorMessage] =
    useState("");
  const [
    jobDescriptionErrorMessageVisibility,
    setJobDescriptionErrorMessageVisibility,
  ] = useState(false);

  let [jobReq, setJobReq] = useState(
    location.state ? location.state.state.jobsDataToEdit.job_requirement : ""
  );
  const [jobReqrrorMessage, setJobReqErrorMessage] = useState("");
  const [jobReqErrorMessageVisibility, setJobReqErrorMessageVisibility] =
    useState(false);

  let [companyDetail, setCompanyDetail] = useState(
    location.state ? location.state.state.jobsDataToEdit.about_company : ""
  );
  const [companyDetailErrorMessage, setCompanyDetailErrorMessage] =
    useState("");
  const [
    companyDetailErrorMessageVisibility,
    setCompanyDetailErrorMessageVisibility,
  ] = useState(false);

  let [jobOpening, setJobOpening] = useState(
    location.state
      ? moment(location.state.state.jobsDataToEdit.openingdate).format(
          "YYYY-MM-DD"
        )
      : ""
  );
  const [jobOpeningErrorMessage, setJobOpeningErrorMessage] = useState("");
  const [
    jobOpeningErrorMessageVisibility,
    setJobOpeningErrorMessageVisibility,
  ] = useState(false);

  let [jobClosing, setJobClosing] = useState(
    location.state
      ? moment(location.state.state.jobsDataToEdit.closingdate).format(
          "YYYY-MM-DD"
        )
      : ""
  );
  const [jobClosingErrorMessage, setJobClosingErrorMessage] = useState("");
  const [
    jobClosingErrorMessageVisibility,
    setJobClosingErrorMessageVisibility,
  ] = useState(false);

  let [managerName, setManagerName] = useState(
    location.state
      ? location.state.state.jobsDataToEdit.hiring_manager_name
      : ""
  );
  const [managerNameErrorMessage, setManagerNameErrorMessage] = useState("");
  const [
    managerNameErrorMessageVisibility,
    setManagerNameErrorMessageVisibility,
  ] = useState(false);

  let [email, setEmail] = useState(
    location.state ? location.state.state.jobsDataToEdit.manager_email : ""
  );
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailErrorMessageVisibility, setEmailErrorMessageVisibility] =
    useState(false);

  let [phone, setPhone] = useState(
    location.state ? location.state.state.jobsDataToEdit.manager_phone : null
  );
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [phoneErrorMessageVisibility, setPhoneErrorMessageVisibility] =
    useState(false);

  let [whatsApp, setWhatsApp] = useState(
    location.state ? location.state.state.jobsDataToEdit.whatsapp_no : null
  );
  const [whatsAppErrorMessage, setWhatsAppErrorMessage] = useState("");
  const [whatsAppErrorMessageVisibility, setWhatsAppErrorMessageVisibility] =
    useState(false);

  let [linkedIn, setLinkedIn] = useState(
    location.state ? location.state.state.jobsDataToEdit.manager_linkedin : ""
  );
  const [linkedInErrorMessage, setLinkedInErrorMessage] = useState("");
  const [linkedInErrorMessageVisibility, setLinkedInErrorMessageVisibility] =
    useState(false);

  let [status, setStatus] = useState(
    location.state ? location.state.state.jobsDataToEdit.status : "Hold"
  );
  const [apiErrorMessage, setApiErrorMessage] = useState(""); // Added for API errors

  const handleImageFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      if (type === "companyLogo") {
        setCompanyLogo(file);
        setCompanyLogoPreview(URL.createObjectURL(file));
        setImageErrorMessageAndVisibility("companyLogo", false, "");
      } else if (type === "managerImage") {
        setManagerImage(file);
        setManagerImagePreview(URL.createObjectURL(file));
        setImageErrorMessageAndVisibility("managerImage", false, "");
      }
    } else {
      setImageErrorMessageAndVisibility(type, true, "Select an image file");
    }
  };

  const setImageErrorMessageAndVisibility = (type, visibility, text) => {
    if (type === "companyLogo") {
      setCompanyLogoErrorMessageVisibility(visibility);
      setCompanyLogoErrorMessage(text);
    } else if (type === "managerImage") {
      setManagerImageErrorMessageVisibility(visibility);
      setManagerImageErrorMessage(text);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Title Validation
  const onTitleTextChangeListener = (event) => {
    setTitle((title = event.currentTarget.value));
    setTitleErrorMessageAndVisibilityAndIsValid(title);
  };
  const setTitleErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setTitleErrorMessageAndVisibility(true, "Enter Title");
      return isViewValid;
    } else {
      setTitleErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setTitleErrorMessageAndVisibility = (visibility, text) => {
    setTitleErrorMessageVisibility(visibility);
    setTitleErrorMessage(text);
  };

  //#endregion
  //#region Working Hours Validation
  const onWorkingHoursTextChangeListener = (event) => {
    setWorkingHours(event.currentTarget.value);
    setWorkingHoursErrorMessageAndVisibilityAndIsValid(workingHours);
  };

  const setWorkingHoursErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setWorkingHoursErrorMessageAndVisibility(true, "Enter Working Hours");
      return isViewValid;
    } else {
      setWorkingHoursErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setWorkingHoursErrorMessageAndVisibility = (visibility, text) => {
    setWorkingHoursErrorMessageVisibility(visibility);
    setWorkingHoursErrorMessage(text);
  };
  //#endregion

  //#region Weekly Offs Validation
  const onWeeklyOffsTextChangeListener = (event) => {
    setWeeklyOffs(event.currentTarget.value);
    setWeeklyOffsErrorMessageAndVisibilityAndIsValid(weeklyOffs);
  };

  const setWeeklyOffsErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setWeeklyOffsErrorMessageAndVisibility(true, "Enter Weekly Offs");
      return isViewValid;
    } else {
      setWeeklyOffsErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setWeeklyOffsErrorMessageAndVisibility = (visibility, text) => {
    setWeeklyOffsErrorMessageVisibility(visibility);
    setWeeklyOffsErrorMessage(text);
  };
  //#endregion

  //#region Hiring Manager Validation
  const onHiringManagerTextChangeListener = (event) => {
    setHiringManager(event.currentTarget.value);
    setHiringManagerErrorMessageAndVisibilityAndIsValid(hiringManager);
  };

  const setHiringManagerErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setHiringManagerErrorMessageAndVisibility(true, "Enter Hiring Manager");
      return isViewValid;
    } else {
      setHiringManagerErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setHiringManagerErrorMessageAndVisibility = (visibility, text) => {
    setHiringManagerErrorMessageVisibility(visibility);
    setHiringManagerErrorMessage(text);
  };
  //#endregion

  //#region Min Salary Validation
  const onMinSalaryTextChangeListener = (event) => {
    setMinSalary(event.currentTarget.value);
    setMinSalaryErrorMessageAndVisibilityAndIsValid(minSalary);
  };

  const setMinSalaryErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setMinSalaryErrorMessageAndVisibility(true, "Enter Minimum Salary");
      return isViewValid;
    } else {
      setMinSalaryErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setMinSalaryErrorMessageAndVisibility = (visibility, text) => {
    setMinSalaryErrorMessageVisibility(visibility);
    setMinSalaryErrorMessage(text);
  };
  //#endregion
  //#region Max Salary Validation
  const onMaxSalaryTextChangeListener = (event) => {
    setMaxSalary(event.currentTarget.value);
    setMaxSalaryErrorMessageAndVisibilityAndIsValid(maxSalary);
  };

  const setMaxSalaryErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setMaxSalaryErrorMessageAndVisibility(true, "Enter Maximum Salary");
      return isViewValid;
    } else {
      setMaxSalaryErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setMaxSalaryErrorMessageAndVisibility = (visibility, text) => {
    setMaxSalaryErrorMessageVisibility(visibility);
    setMaxSalaryErrorMessage(text);
  };

  //#region Number of Openings Validation
  const onNumberOfOpeningsTextChangeListener = (event) => {
    setNumberOfOpenings(event.currentTarget.value);
    setNumberOfOpeningsErrorMessageAndVisibilityAndIsValid(numberOfOpenings);
  };

  const setNumberOfOpeningsErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (
      isNullOrEmpty(enteredText) ||
      isNaN(enteredText) ||
      parseInt(enteredText) <= 0
    ) {
      setNumberOfOpeningsErrorMessageAndVisibility(
        true,
        "Enter a valid number of openings"
      );
      return isViewValid;
    } else {
      setNumberOfOpeningsErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setNumberOfOpeningsErrorMessageAndVisibility = (visibility, text) => {
    setNumberOfOpeningsErrorMessageVisibility(visibility);
    setNumberOfOpeningsErrorMessage(text);
  };
  //#endregion

  const onExperienceTextChangeListener = (event) => {
    setExperience((experience = event.currentTarget.value));
    setExperienceErrorMessageAndVisibilityAndIsValid(experience);
  };

  // const setExperienceErrorMessageAndVisibilityAndIsValid = (enteredText) => {
  //   var isViewValid = false;
  //   if (isNullOrEmpty(enteredText)) {
  //     setExperienceErrorMessageAndVisibility(true, "Enter Experience");
  //     return isViewValid;
  //   } else {
  //     setExperienceErrorMessageAndVisibility(false, "");
  //     return (isViewValid = true);
  //   }
  // };

  const setExperienceErrorMessageAndVisibility = (visibility, text) => {
    setExperienceErrorMessageVisibility(visibility);
    setExperienceErrorMessage(text);
  };
  //#endregion

  //#endregion

  //#region Job Description Validation
  const onJobDescriptionTextChangeListener = (event) => {
    setJobDescription((jobDescription = event));
    setJobDescriptionErrorMessageAndVisibilityAndIsValid(jobDescription);
  };
  const setJobDescriptionErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setJobDescriptionErrorMessageAndVisibility(true, "Enter Job Description");
      return isViewValid;
    } else {
      setJobDescriptionErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setJobDescriptionErrorMessageAndVisibility = (visibility, text) => {
    setJobDescriptionErrorMessageVisibility(visibility);
    setJobDescriptionErrorMessage(text);
  };

  //#endregion

  //#region Job Req Validation
  const onJobReqTextChangeListener = (event) => {
    setJobReq((jobReq = event));
    setJobReqErrorMessageAndVisibilityAndIsValid(jobReq);
  };
  const setJobReqErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setJobReqErrorMessageAndVisibility(true, "Enter Job Requirment");
      return isViewValid;
    } else {
      setJobReqErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setJobReqErrorMessageAndVisibility = (visibility, text) => {
    setJobReqErrorMessageVisibility(visibility);
    setJobReqErrorMessage(text);
  };

  //#endregion

  //#region Company Details Validation
  const onCompanyDetailsTextChangeListener = (event) => {
    setCompanyDetail((companyDetail = event));
    setCompanyDetailsErrorMessageAndVisibilityAndIsValid(companyDetail);
  };
  const setCompanyDetailsErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setCompanyDetailsErrorMessageAndVisibility(true, "Enter Company Detail");
      return isViewValid;
    } else {
      setCompanyDetailsErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setCompanyDetailsErrorMessageAndVisibility = (visibility, text) => {
    setCompanyDetailErrorMessageVisibility(visibility);
    setCompanyDetailErrorMessage(text);
  };

  //#endregion

  //#region Job Opening Validation
  const onJobOpeningTextChangeListener = (event) => {
    setJobOpening((jobOpening = event.currentTarget.value));
    setJobOpeningErrorMessageAndVisibilityAndIsValid(jobOpening);
  };
  const setJobOpeningErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setJobOpeningErrorMessageAndVisibility(true, "Select Job Opening Date");
      return isViewValid;
    } else {
      setJobOpeningErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setJobOpeningErrorMessageAndVisibility = (visibility, text) => {
    setJobOpeningErrorMessageVisibility(visibility);
    setJobOpeningErrorMessage(text);
  };

  //#endregion

  //#region Job Closing Validation
  const onJobClosingTextChangeListener = (event) => {
    setJobClosing((jobClosing = event.currentTarget.value));
    setJobClosingErrorMessageAndVisibilityAndIsValid(jobClosing);
  };
  const setJobClosingErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setJobClosingErrorMessageAndVisibility(true, "Select Job Closing Date");
      return isViewValid;
    } else {
      setJobClosingErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setJobClosingErrorMessageAndVisibility = (visibility, text) => {
    setJobClosingErrorMessageVisibility(visibility);
    setJobClosingErrorMessage(text);
  };

  //#endregion

  //#region Manager Name Validation
  const onManagerNameTextChangeListener = (event) => {
    setManagerName((managerName = event.currentTarget.value));
    setManagerNameErrorMessageAndVisibilityAndIsValid(managerName);
  };
  const setManagerNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setManagerNameErrorMessageAndVisibility(true, "Enter Name");
      return isViewValid;
    } else {
      setManagerNameErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setManagerNameErrorMessageAndVisibility = (visibility, text) => {
    setManagerNameErrorMessageVisibility(visibility);
    setManagerNameErrorMessage(text);
  };

  //#endregion

  //#region Manager Email Validation
  const onManagerEmailTextChangeListener = (event) => {
    setEmail((email = event.currentTarget.value));
    setManagerEmailErrorMessageAndVisibilityAndIsValid(email);
  };
  const setManagerEmailErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setManagerEmailErrorMessageAndVisibility(true, "Enter Email");
      return isViewValid;
    } else {
      setManagerEmailErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setManagerEmailErrorMessageAndVisibility = (visibility, text) => {
    setEmailErrorMessageVisibility(visibility);
    setEmailErrorMessage(text);
  };

  //#endregion

  //#region Manager Phone Validation
  const onManagerPhoneTextChangeListener = (event) => {
    setPhone((phone = event.currentTarget.value));
    setPhoneErrorMessageAndVisibilityAndIsValid(phone);
  };
  const setPhoneErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setPhoneErrorMessageAndVisibility(true, "Enter Phone Number");
      return isViewValid;
    } else {
      setPhoneErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setPhoneErrorMessageAndVisibility = (visibility, text) => {
    setPhoneErrorMessageVisibility(visibility);
    setPhoneErrorMessage(text);
  };
  //#endregion

  //#region Manager Whatsapp Validation
  const onWhatsappTextChangeListener = (event) => {
    setWhatsApp((whatsApp = event.currentTarget.value));
    setWhatsAppErrorMessageAndVisibilityAndIsValid(whatsApp);
  };
  const setWhatsAppErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setWhatsAppErrorMessageAndVisibility(true, "Enter WhatsApp Number");
      return isViewValid;
    } else {
      setWhatsAppErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setWhatsAppErrorMessageAndVisibility = (visibility, text) => {
    setWhatsAppErrorMessageVisibility(visibility);
    setWhatsAppErrorMessage(text);
  };

  //#endregion

  //#region Manager LinkedIn Validation
  const onLinkedInTextChangeListener = (event) => {
    setLinkedIn((linkedIn = event.currentTarget.value));
    setLinkedInErrorMessageAndVisibilityAndIsValid(linkedIn);
  };
  const setLinkedInErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setLinkedInErrorMessageAndVisibility(true, "Enter LinkedIn URL");
      return isViewValid;
    } else {
      setLinkedInErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setLinkedInErrorMessageAndVisibility = (visibility, text) => {
    setLinkedInErrorMessageVisibility(visibility);
    setLinkedInErrorMessage(text);
  };

  //#endregion

  function validView() {
    if (
      title &&
      selectedExperience &&
      workingHours &&
      weeklyOffs &&
      companyDetail &&
      jobDescription &&
      jobReq &&
      numberOfOpenings &&
      selectedPriority &&
      selectedJobType &&
      jobSkills.length > 0 &&
      selectedEducations.length > 0 &&
      hiringManager &&
      email &&
      whatsApp &&
      linkedIn &&
      jobAddress &&
      phone &&
      jobOpening &&
      jobClosing &&
      minSalary &&
      maxSalary &&
      companyLogo &&
      managerImage
    ) {
      return true;
    }

    // If any field is missing, return false
    return false;
  }

  const postJobApiCall = () => {
    let formData = new FormData();
    formData.append("status", 1);
    formData.append("categories_id", selectedCategory);
    formData.append("job_typeId", selectedJobType);
    formData.append("priority", selectedPriority);

    if (title) formData.append("job_title", title || "");
    if (selectedExperience)
      formData.append("experience", selectedExperience.label || "");
    if (companyLogo) formData.append("poster_image", companyLogo);
    if (workingHours) formData.append("working_hours", workingHours || "0");
    if (weeklyOffs) formData.append("weekly_off", weeklyOffs || "0");
    if (companyDetail) formData.append("about_company", companyDetail || "");
    if (jobDescription)
      formData.append("job_description", jobDescription || "");
    if (jobReq) formData.append("job_requirement", jobReq || "");
    if (numberOfOpenings)
      formData.append("opening_no", numberOfOpenings || "0");

    jobSkills.forEach((element, i) => {
      formData.append(`skill_id[${i}]`, element._id);
    });

    selectedEducations.forEach((element, i) => {
      formData.append(`education_id[${i}]`, element.value);
    });

    if (hiringManager)
      formData.append("hiring_manager_name", hiringManager || "");
    if (managerImage) formData.append("manager_image", managerImage);
    if (email) formData.append("manager_email", email || "");
    if (whatsApp) formData.append("whatsapp_no", whatsApp || "");
    if (linkedIn) formData.append("manager_linkedin", linkedIn || "");
    if (jobAddress) formData.append("address", jobAddress || "");
    if (phone) formData.append("manager_phone", phone || "");
    if (jobOpening) formData.append("start_date", jobOpening || "");
    if (jobClosing) formData.append("end_date", jobClosing || "");
    if (minSalary) formData.append("min_salary", minSalary || "0");
    if (maxSalary) formData.append("max_salary", maxSalary || "0");

    if (validView()) {
      setIsLoading(true);
      addEditJob(formData)
        .then((res) => {
          setIsLoading(false);
          if (ERROR_CODES.SUCCESS == res.data.error_code) {
            history.push("/dashboard/job-listing");
          } else {
            setApiErrorMessage(res.data.message || "An error occurred.");
            console.log(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setApiErrorMessage("Fill all fields");
      // alert("Filled all fields");
    }
  };
  //#endregion

  //#region other functions

  useEffect(() => {
    if (location.state && location.state.state.jobsDataToEdit) {
      console.log(location.state.state.jobsDataToEdit);

      const { _id } = location.state.state.jobsDataToEdit;
      setId(_id);
    }
    window.scrollTo(0, 0);
  }, []);
  //#endregion
  const [jobAddress, setJobAddress] = useState(
    location.state ? location.state.state.jobsDataToEdit.address : ""
  );
  const [jobAddressErrorMessage, setJobAddressErrorMessage] = useState("");
  const [
    jobAddressErrorMessageVisibility,
    setJobAddressErrorMessageVisibility,
  ] = useState(false);

  const onJobAddressTextChangeListener = (event) => {
    setJobAddress(event.currentTarget.value);
    setJobAddressErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };

  const setJobAddressErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (enteredText.trim() === "") {
      setJobAddressErrorMessageAndVisibility(true, "Enter Job Address");
      return false;
    } else {
      setJobAddressErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setJobAddressErrorMessageAndVisibility = (visibility, text) => {
    setJobAddressErrorMessageVisibility(visibility);
    setJobAddressErrorMessage(text);
  };
  const [_id, setId] = useState(null);

  const addEditCategoryApiCall = async () => {
    let formData = new FormData();
    if (_id) {
      formData.append("_id", _id);
    } // formData.append("user_id", null)
    formData.append("categories_id", selectedCategory);
    formData.append("job_typeId", selectedJobType);
    formData.append("priority", selectedPriority);

    if (title) formData.append("job_title", title || "");
    if (selectedExperience)
      formData.append("experience", selectedExperience.label || "");
    if (companyLogo) formData.append("poster_image", companyLogo);
    if (workingHours) formData.append("working_hours", workingHours || "0");
    if (weeklyOffs) formData.append("weekly_off", weeklyOffs || "0");
    if (companyDetail) formData.append("about_company", companyDetail || "");
    if (jobDescription)
      formData.append("job_description", jobDescription || "");
    if (jobReq) formData.append("job_requirement", jobReq || "");
    if (numberOfOpenings)
      formData.append("opening_no", numberOfOpenings || "0");
    
    selectedJobSkills.forEach((element, i) => {
      formData.append(`skill_id[${i}]`, element.value);
    });

    selectedEducations.forEach((element, i) => {
      formData.append(`education_id[${i}]`, element.value);
    });

    if (hiringManager)
      formData.append("hiring_manager_name", hiringManager || "");
    if (managerImage) formData.append("manager_image", managerImage);
    if (email) formData.append("manager_email", email || "");
    if (whatsApp) formData.append("whatsapp_no", whatsApp || "");
    if (linkedIn) formData.append("manager_linkedin", linkedIn || "");
    if (jobAddress) formData.append("address", jobAddress || "");
    if (phone) formData.append("manager_phone", phone || "");
    if (jobOpening) formData.append("start_date", jobOpening || "");
    if (jobClosing) formData.append("end_date", jobClosing || "");
    if (minSalary) formData.append("min_salary", minSalary || "0");
    if (maxSalary) formData.append("max_salary", maxSalary || "0");

    if (true) {
      setIsLoading(true);
      try {
        let response;
        if (_id) {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}jobPosting/update-Jobpost`,
            formData,
            {
              ["axios-retry"]: {
                retries: 5,
              },
            }
          );
        }
        setIsLoading(false);
        if (ERROR_CODES.ADD_EDIT_SUCCESS === response.data.error_code) {
          history.push("/dashboard/job-listing");
        } else {
          setApiErrorMessage(response.data.message || "An error occurred.");
          console.log(response.data.errorMessage);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
      }
    }
  };
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    location.state ? location.state.state.jobsDataToEdit.categories_id : null
  );
  const [categoryErrorMessage, setCategoryErrorMessage] = useState("");
  const [categoryErrorMessageVisibility, setCategoryErrorMessageVisibility] =
    useState(false);

  useEffect(() => {
    getCategories(1, 100)
      .then((response) => {
        setCategories(response.data.data);
        if (location.state && location.state.state.jobsDataToEdit) {
          const category = response.data.data.find(
            (cat) =>
              cat._id === location.state.state.jobsDataToEdit.categories_id
          );
          if (category) {
            setSelectedCategory(category._id);
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching categories: ", error);
      });
  }, [location.state]);

  const onCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption ? selectedOption.value : null);
    setCategoryErrorMessageAndVisibilityAndIsValid(selectedOption);
  };

  const setCategoryErrorMessageAndVisibilityAndIsValid = (selectedOption) => {
    if (!selectedOption) {
      setCategoryErrorMessageAndVisibility(true, "Select a Category");
      return false;
    } else {
      setCategoryErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setCategoryErrorMessageAndVisibility = (visibility, text) => {
    setCategoryErrorMessageVisibility(visibility);
    setCategoryErrorMessage(text);
  };

  const [priorities, setPriorities] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(
    location.state ? location.state.state.jobsDataToEdit.priority.id : null
  );
  const [priorityErrorMessage, setPriorityErrorMessage] = useState("");
  const [priorityErrorMessageVisibility, setPriorityErrorMessageVisibility] =
    useState(false);

  useEffect(() => {
    lookUpDataForJobPosting()
      .then((response) => {
        setPriorities(response.data.result.priority);
        if (location.state && location.state.state.jobsDataToEdit) {
          const priority = response.data.result.priority.find(
            (pri) => pri._id === location.state.state.jobsDataToEdit.priority.id
          );
          if (priority) {
            setSelectedPriority(priority._id);
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching priorities: ", error);
      });
  }, [location.state]);
  const onPriorityChange = (selectedOption) => {
    setSelectedPriority(selectedOption ? selectedOption.value : null);
    setPriorityErrorMessageAndVisibilityAndIsValid(selectedOption);
  };

  const setPriorityErrorMessageAndVisibilityAndIsValid = (selectedOption) => {
    if (!selectedOption) {
      setPriorityErrorMessageAndVisibility(true, "Select a Priority");
      return false;
    } else {
      setPriorityErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setPriorityErrorMessageAndVisibility = (visibility, text) => {
    setPriorityErrorMessageVisibility(visibility);
    setPriorityErrorMessage(text);
  };

  const [jobTypes, setJobTypes] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState(
    location.state ? location.state.state.jobsDataToEdit.job_type.id : null
  );
  const [jobTypeErrorMessage, setJobTypeErrorMessage] = useState("");
  const [jobTypeErrorMessageVisibility, setJobTypeErrorMessageVisibility] =
    useState(false);
  useEffect(() => {
    getJobType(1, 100)
      .then((response) => {
        setJobTypes(response.data.data);
        if (location.state && location.state.state.jobsDataToEdit) {
          const jobType = response.data.data?.find(
            (job) => job._id === location.state.state.jobsDataToEdit.job_type.id
          );
          if (jobType) {
            setSelectedJobType(jobType._id);
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching job types: ", error);
      });
  }, [location.state]);

  // Handle Job Type Change
  const onJobTypeChange = (selectedOption) => {
    setSelectedJobType(selectedOption ? selectedOption.value : null);
    setJobTypeErrorMessageAndVisibilityAndIsValid(selectedOption);
  };

  const setJobTypeErrorMessageAndVisibilityAndIsValid = (selectedOption) => {
    if (!selectedOption) {
      setJobTypeErrorMessageAndVisibility(true, "Select a Job Type");
      return false;
    } else {
      setJobTypeErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setJobTypeErrorMessageAndVisibility = (visibility, text) => {
    setJobTypeErrorMessageVisibility(visibility);
    setJobTypeErrorMessage(text);
  };

  // 
  const [jobSkills, setJobSkills] = useState([]);
  const [selectedJobSkills, setSelectedJobSkills] = useState(
    location.state && location.state.state.jobsDataToEdit.skill_id
      ? location.state.state.jobsDataToEdit.skill_id.map((skill) => ({
          value: skill.id,
          label: skill.title,
        }))
      : []
  );
  const [jobSkillErrorMessage, setJobSkillErrorMessage] = useState("");
  const [jobSkillErrorMessageVisibility, setJobSkillErrorMessageVisibility] =
    useState(false);

  // Fetch Job Skills Data
  useEffect(() => {
    getJobSkill()
      .then((response) => {
        setJobSkills(response.data.data);

        if (location.state && location.state.state.jobsDataToEdit) {
          const skills = response.data.data.filter((skill) =>
            location.state.state.jobsDataToEdit.skill_id.some(
              (s) => s.id === skill._id
            )
          );

          if (skills.length) {
            setSelectedJobSkills(
              skills.map((skill) => ({ value: skill._id, label: skill.title }))
            );
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching job skills: ", error);
      });
  }, [location.state]);

  // Handle Job Skills Change
  const onJobSkillChange = (selectedOptions) => {
    setSelectedJobSkills(selectedOptions || []);
    setJobSkillErrorMessageAndVisibilityAndIsValid(selectedOptions);
  };
  const setJobSkillErrorMessageAndVisibilityAndIsValid = (selectedOptions) => {
    if (!selectedOptions || !selectedOptions.length) {
      setJobSkillErrorMessageAndVisibility(
        true,
        "Select at least one Job Skill"
      );
      return false;
    } else {
      setJobSkillErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setJobSkillErrorMessageAndVisibility = (visibility, text) => {
    setJobSkillErrorMessageVisibility(visibility);
    setJobSkillErrorMessage(text);
  };

  const [educations, setEducations] = useState([]);
  const [selectedEducations, setSelectedEducations] = useState(
    location.state && location.state.state.jobsDataToEdit.education_id
      ? location.state.state.jobsDataToEdit.education_id.map((education) => ({
          value: education.id,
          label: education.study,
        }))
      : []
  );
  const [educationErrorMessage, setEducationErrorMessage] = useState("");
  const [educationErrorMessageVisibility, setEducationErrorMessageVisibility] =
    useState(false);

  // Fetch Education Data
  useEffect(() => {
    getEducation()
      .then((response) => {
        setEducations(response.data.data);
        if (location.state && location.state.state.jobsDataToEdit) {
          const selectedEducation = response.data.data.filter((education) =>
            location.state.state.jobsDataToEdit.education_id.some(
              (e) => e.id === education._id
            )
          );
          if (selectedEducation.length) {
            setSelectedEducations(
              selectedEducation.map((education) => ({
                value: education._id,
                label: education.study,
              }))
            );
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching educations: ", error);
      });
  }, [location.state]);

  // Handle Education Change
  const onEducationChange = (selectedOptions) => {
    setSelectedEducations(selectedOptions || []);
    setEducationErrorMessageAndVisibilityAndIsValid(selectedOptions);
  };

  // Handle Error Messages
  const setEducationErrorMessageAndVisibilityAndIsValid = (selectedOptions) => {
    if (!selectedOptions || !selectedOptions.length) {
      setEducationErrorMessageAndVisibility(
        true,
        "Select at least one Education"
      );
      return false;
    } else {
      setEducationErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setEducationErrorMessageAndVisibility = (visibility, text) => {
    setEducationErrorMessageVisibility(visibility);
    setEducationErrorMessage(text);
  };
  
  const [selectedExperience, setSelectedExperience] = useState(
    location.state && location.state.state.jobsDataToEdit.experience
      ? {
          value: location.state.state.jobsDataToEdit.experience,
          label: location.state.state.jobsDataToEdit.experience,
        }
      : null
    //  location.state ? location.state.state.jobsDataToEdit.experience : ""
  );

  const onExperienceChange = (selectedOption) => {
    setSelectedExperience(selectedOption);
    setExperienceErrorMessageAndVisibilityAndIsValid(selectedOption);
  };

  const setExperienceErrorMessageAndVisibilityAndIsValid = (selectedOption) => {
    if (!selectedOption) {
      setExperienceErrorMessageAndVisibility(
        true,
        "Select a valid Job Experience"
      );
      return false;
    } else {
      setExperienceErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;
  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div className="panel__conatainer">
          <div className="panel__heading">Post a Job</div>
          <div style={{ flex: 1 }}>
            <div className="company__details__content">
              <div
                className="company__details__row"
                style={{ marginTop: "2em" }}
              >
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Category
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <Select
                    options={categories.map((category) => ({
                      value: category._id, // ID as value
                      label: category.name, // Name as label
                    }))}
                    value={
                      categories.find(
                        (category) => category._id === selectedCategory
                      )
                        ? {
                            value: selectedCategory._id,
                            label: categories.find(
                              (category) => category._id === selectedCategory
                            )?.name,
                          }
                        : null
                    }
                    onChange={(option) => onCategoryChange(option)}
                    placeholder="Select Category"
                  />
                  <div className="input__text__error__msg">
                    {categoryErrorMessageVisibility
                      ? categoryErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Title
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="Graphic design"
                    ClassName="input__box"
                    type="text"
                    value={title}
                    onChange={(event) => onTitleTextChangeListener(event)}
                  />
                  <div className="input__text__error__msg">
                    {titleErrorMessageVisibility ? titleErrorMessage : null}
                  </div>
                </div>
              </div>
              <div
                className="company__details__row"
                style={{ marginTop: "2em" }}
              >
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Type
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <Select
                    options={jobTypes.map((jobType) => ({
                      value: jobType._id,
                      label: jobType.title,
                    }))}
                    value={
                      jobTypes.find(
                        (jobType) => jobType._id === selectedJobType
                      )
                        ? {
                            value: selectedJobType,
                            label: jobTypes.find(
                              (jobType) => jobType._id === selectedJobType
                            )?.title,
                          }
                        : null
                    }
                    onChange={onJobTypeChange}
                    placeholder="Select Job Type"
                  />
                  <div className="input__text__error__msg">
                    {jobTypeErrorMessageVisibility ? jobTypeErrorMessage : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Skills
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <Select
                    options={jobSkills.map((skill) => ({
                      value: skill._id,
                      label: skill.title,
                    }))}
                    value={selectedJobSkills}
                    onChange={onJobSkillChange}
                    placeholder="Select Job Skills"
                    isMulti
                  />
                  <div className="input__text__error__msg">
                    {jobSkillErrorMessageVisibility
                      ? jobSkillErrorMessage
                      : null}
                  </div>
                </div>
              </div>

              <div
                className="company__details__row"
                style={{ marginTop: "2em" }}
              >
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Priority
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <Select
                    options={priorities.map((priority) => ({
                      value: priority._id, // ID as value
                      label: priority.title, // Name as label
                    }))}
                    value={
                      priorities.find(
                        (priority) => priority._id === selectedPriority
                      )
                        ? {
                            value: selectedPriority,
                            label: priorities.find(
                              (priority) => priority._id === selectedPriority
                            )?.title,
                          }
                        : null
                    }
                    onChange={(option) => onPriorityChange(option)}
                    placeholder="Select Priority"
                  />
                  <div className="input__text__error__msg">
                    {priorityErrorMessageVisibility
                      ? priorityErrorMessage
                      : null}
                  </div>
                </div>
              </div>

              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Address
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="Enter Job Address"
                    ClassName="input__box"
                    type="text"
                    value={jobAddress}
                    onChange={onJobAddressTextChangeListener}
                  />
                  <div className="input__text__error__msg">
                    {jobAddressErrorMessageVisibility
                      ? jobAddressErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Education
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <Select
                    options={educations.map((education) => ({
                      value: education._id,
                      label: education.study,
                    }))}
                    value={selectedEducations}
                    onChange={onEducationChange}
                    placeholder="Select Education"
                    isMulti
                  />
                  <div className="input__text__error__msg">
                    {educationErrorMessageVisibility
                      ? educationErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Experience
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <Select
                    options={experiencesOption.map((experience) => ({
                      value: experience.value,
                      label: experience.label,
                    }))}
                    value={selectedExperience}
                    onChange={onExperienceChange}
                    placeholder="Select Experience"
                  />
                  <div className="input__text__error__msg">
                    {experienceErrorMessageVisibility
                      ? experienceErrorMessage
                      : null}
                  </div>
                </div>
              </div>

              <div className="company__details__row__textarea">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Description
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <TextArea
                    value={jobDescription}
                    onChange={(value) =>
                      onJobDescriptionTextChangeListener(value)
                    }
                  />
                  <div
                    className="input__text__error__msg"
                    style={{ bottom: "-19px" }}
                  >
                    {jobDescriptionErrorMessageVisibility
                      ? jobDescriptionErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row__textarea">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Requirment
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <TextArea
                    value={jobReq}
                    onChange={(value) => onJobReqTextChangeListener(value)}
                  />
                  <div
                    className="input__text__error__msg"
                    style={{ bottom: "-19px" }}
                  >
                    {jobReqErrorMessageVisibility ? jobReqrrorMessage : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row__textarea">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Company Details
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <TextArea
                    value={companyDetail}
                    onChange={(value) =>
                      onCompanyDetailsTextChangeListener(value)
                    }
                  />
                  <div
                    className="input__text__error__msg"
                    style={{ bottom: "-19px" }}
                  >
                    {companyDetailErrorMessageVisibility
                      ? companyDetailErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Working Hours
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="8 hours"
                    ClassName="input__box"
                    type="number"
                    value={workingHours}
                    onChange={(event) =>
                      onWorkingHoursTextChangeListener(event)
                    }
                  />
                  {/* <div className="input__text__error__msg">
                    {workingHoursErrorMessageVisibility
                      ? workingHoursErrorMessage
                      : null}
                  </div> */}
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Company Logo
                  </div>
                </div>
                <div className="category__details__row__input__box">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      handleImageFileChange(event, "companyLogo")
                    }
                  />
                  <div className="input__text__error__msg">
                    {companyLogoErrorMessageVisibility
                      ? companyLogoErrorMessage
                      : null}
                  </div>
                  {companyLogoPreview && (
                    <div className="image__preview">
                      <img
                        src={companyLogoPreview}
                        alt="Company Logo Preview"
                        style={{
                          padding: "10px",
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                  {/* <InputBox
                    placeholder="Company Logo"
                    ClassName="input__box"
                    type="text"
                    value={companyLogoPreview || ""}
                    readOnly
                  /> */}
                </div>
              </div>

              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Weekly Offs
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="5"
                    ClassName="input__box"
                    type="number"
                    value={weeklyOffs}
                    onChange={(event) => onWeeklyOffsTextChangeListener(event)}
                  />
                  {/* <div className="input__text__error__msg">
                    {weeklyOffsErrorMessageVisibility
                      ? weeklyOffsErrorMessage
                      : null}
                  </div> */}
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Opening Date
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    ClassName="input__box"
                    type="date"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    value={jobOpening}
                    onChange={(event) => onJobOpeningTextChangeListener(event)}
                  />
                  <div className="input__text__error__msg">
                    {jobOpeningErrorMessageVisibility
                      ? jobOpeningErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Closing Date
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    ClassName="input__box"
                    type="date"
                    value={jobClosing}
                    onChange={(event) => onJobClosingTextChangeListener(event)}
                  />
                  <div className="input__text__error__msg">
                    {jobClosingErrorMessageVisibility
                      ? jobClosingErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Manager Name
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="John Doe"
                    ClassName="input__box"
                    type="text"
                    value={hiringManager}
                    onChange={(event) =>
                      onHiringManagerTextChangeListener(event)
                    }
                  />
                  <div className="input__text__error__msg">
                    {hiringManagerErrorMessageVisibility
                      ? hiringManagerErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Manager Picture
                  </div>
                </div>
                <div className="category__details__row__input__box">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      handleImageFileChange(event, "managerImage")
                    }
                  />
                  <div className="input__text__error__msg">
                    {managerImageErrorMessageVisibility
                      ? managerImageErrorMessage
                      : null}
                  </div>
                  {managerImagePreview && (
                    <div className="image__preview">
                      <img
                        src={managerImagePreview}
                        alt="Manager Image Preview"
                        style={{
                          padding: "10px",
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                  {/* <InputBox
                    placeholder="Manager Image"
                    ClassName="input__box"
                    type="text"
                    value={managerImagePreview || ""}
                    readOnly
                  /> */}
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Manager Email
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="johndoe@gmail.com"
                    ClassName="input__box"
                    type="email"
                    value={email}
                    onChange={(event) =>
                      onManagerEmailTextChangeListener(event)
                    }
                  />
                  <div className="input__text__error__msg">
                    {emailErrorMessageVisibility ? emailErrorMessage : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Manager Phone
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="+92 123 456 789"
                    ClassName="input__box"
                    type="text"
                    value={phone}
                    onChange={(event) =>
                      onManagerPhoneTextChangeListener(event)
                    }
                  />
                  <div className="input__text__error__msg">
                    {phoneErrorMessageVisibility ? phoneErrorMessage : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Manger WhatsApp
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="+92 123 456 789"
                    ClassName="input__box"
                    type="tel"
                    value={whatsApp}
                    onChange={(event) => onWhatsappTextChangeListener(event)}
                  />
                  <div className="input__text__error__msg">
                    {whatsAppErrorMessageVisibility
                      ? whatsAppErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Job Manager LinkedIn
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="URL"
                    ClassName="input__box"
                    type="text"
                    value={linkedIn}
                    onChange={(event) => onLinkedInTextChangeListener(event)}
                  />
                  <div className="input__text__error__msg">
                    {linkedInErrorMessageVisibility
                      ? linkedInErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Number of Openings
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="10"
                    ClassName="input__box"
                    type="number"
                    value={numberOfOpenings}
                    onChange={(event) =>
                      onNumberOfOpeningsTextChangeListener(event)
                    }
                  />
                  {/* <div className="input__text__error__msg">
                    {numberOfOpeningsErrorMessageVisibility
                      ? numberOfOpeningsErrorMessage
                      : null}
                  </div> */}
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Min Salary
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="10000"
                    ClassName="input__box"
                    type="number"
                    value={minSalary}
                    onChange={(event) => onMinSalaryTextChangeListener(event)}
                  />
                  <div className="input__text__error__msg">
                    {minSalaryErrorMessageVisibility
                      ? minSalaryErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div className="company__details__row__attribute__heading">
                    Max Salary
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <InputBox
                    placeholder="100000"
                    ClassName="input__box"
                    type="number"
                    value={maxSalary}
                    onChange={(event) => onMaxSalaryTextChangeListener(event)}
                  />
                  <div className="input__text__error__msg">
                    {maxSalaryErrorMessageVisibility
                      ? maxSalaryErrorMessage
                      : null}
                  </div>
                </div>
              </div>
              <div className="company__details__row">
                <div className="company__details__row__attribute">
                  <div
                    className="company__details__row__attribute__heading"
                    style={{ color: "transparent" }}
                  >
                    Salary
                  </div>
                </div>
                <div className="company__details__row__input__box">
                  <ButtonComponent
                    variant="btn"
                    placeholder="Save"
                    onClick={
                      location.state
                        ? () => addEditCategoryApiCall()
                        : () => postJobApiCall()
                    }
                  />
                </div>
              </div>
              {apiErrorMessage && (
                <div className="api-error-message">{apiErrorMessage}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
