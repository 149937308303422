import React, { useEffect, useState } from "react";
import "./JobPostingPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import Select from "react-select";
import UploadImage from "../components/UploadImage";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { useHistory, useLocation } from "react-router-dom";
import { addEditPortfolio, getTechnology, getServices } from "../APIs/Repos";
import isNullOrEmpty from "../Utils/TextUtils";
import { getBase64 } from "../Utils/TextUtils";
import TextArea from "../components/TextArea";
import { ERROR_CODES } from "../Constants";

export default function PortfolioForm() {
  //#region Variables

  const location = useLocation();
  const history = useHistory();

  const [id, setId] = useState(
    location.state ? location.state.state.portfolioDataToEdit.id : 0
  );

  let [title, setTitle] = useState(
    location.state ? location.state.state.portfolioDataToEdit.name : ""
  );
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [titleErrorMessageVisibility, setTitleErrorMessageVisibility] =
    useState(false);

  let [description, setDescription] = useState(
    location.state ? location.state.state.portfolioDataToEdit.description : ""
  );
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [
    descriptionErrorMessageVisibility,
    setDescriptionErrorMessageVisibility,
  ] = useState(false);

  let [detail, setDetail] = useState(
    location.state ? location.state.state.portfolioDataToEdit.detail : ""
  );
  const [detailErrorMessage, setDetailErrorMessage] = useState("");
  const [detailErrorMessageVisibility, setDetailErrorMessageVisibility] =
    useState(false);

  let [technology, setTechnology] = useState("");
  const [technologyErrorMessage, setTechnologyErrorMessage] = useState("");
  const [
    technologyErrorMessageVisibility,
    setTechnologyErrorMessageVisibility,
  ] = useState(false);

  let [services, setServices] = useState("");
  const [servicesErrorMessage, setServicesErrorMessage] = useState("");
  const [servicesErrorMessageVisibility, setServicesErrorMessageVisibility] =
    useState(false);

  let [image, setImage] = useState(
    location.state ? location.state.state.portfolioDataToEdit.thumnail : ""
  );
  let [Image_base64, setImage_base64] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  let [technologyArray, setTechnologyArray] = useState([]);
  let [servicesArray, setServicesArray] = useState([]);

  const [technologyOptions, setTechnologyOptions] = useState(null);
  const [servicesOptions, setServicesOptions] = useState(null);
  //#endregion

  //#region Title Validation
  const onTitleTextChangeListener = (event) => {
    setTitle((title = event.currentTarget.value));
    setTitleErrorMessageAndVisibilityAndIsValid(title);
  };
  const setTitleErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setTitleErrorMessageAndVisibility(true, "Enter Title");
      return isViewValid;
    } else {
      setTitleErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setTitleErrorMessageAndVisibility = (visibility, text) => {
    setTitleErrorMessageVisibility(visibility);
    setTitleErrorMessage(text);
  };

  //#endregion

  //#region Description Validation
  const onDescriptionTextChangeListener = (event) => {
    setDescription((description = event));
    setDescriptionErrorMessageAndVisibilityAndIsValid(description);
  };
  const setDescriptionErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setDescriptionErrorMessageAndVisibility(true, "Enter Description");
      return isViewValid;
    } else {
      setDescriptionErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setDescriptionErrorMessageAndVisibility = (visibility, text) => {
    setDescriptionErrorMessageVisibility(visibility);
    setDescriptionErrorMessage(text);
  };

  //#endregion

  //#region Detail Validation
  const onDetailTextChangeListener = (event) => {
    setDetail((detail = event));
    setDetailErrorMessageAndVisibilityAndIsValid(description);
  };
  const setDetailErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setDetailErrorMessageAndVisibility(true, "Enter Detail");
      return isViewValid;
    } else {
      setDetailErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setDetailErrorMessageAndVisibility = (visibility, text) => {
    setDetailErrorMessageVisibility(visibility);
    setDetailErrorMessage(text);
  };

  //#endregion

  //#region Technology Validation
  const onTechnologyTextChangeListener = (event) => {
    setTechnology((technology = event));
    setTechnologyErrorMessageAndVisibilityAndIsValid(technology);
  };
  const setTechnologyErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setTechnologyErrorMessageAndVisibility(true, "Select Technology");
      return isViewValid;
    } else {
      setTechnologyErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setTechnologyErrorMessageAndVisibility = (visibility, text) => {
    setTechnologyErrorMessageVisibility(visibility);
    setTechnologyErrorMessage(text);
  };

  //#endregion

  //#region Services Validation
  const onServicesTextChangeListener = (event) => {
    setServices((services = event));
    setServicesErrorMessageAndVisibilityAndIsValid(services);
  };
  const setServicesErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setServicesErrorMessageAndVisibility(true, "Select Services");
      return isViewValid;
    } else {
      setServicesErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }
  };

  const setServicesErrorMessageAndVisibility = (visibility, text) => {
    setServicesErrorMessageVisibility(visibility);
    setServicesErrorMessage(text);
  };

  //#endregion

  //#region other function

  useEffect(() => {
    getServicesApiCall();
    getTechnologyApiCall();
    if (
      location.state &&
      location.state.state.portfolioDataToEdit.portfoliotechnologies
    )
      setTechnologyValue();
    if (
      location.state &&
      location.state.state.portfolioDataToEdit.portfolioservices
    )
      setServicesValue();
  }, []);

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const fileSelectedHandler = async (img) => {
    await getBase64(img, (result) => {
      setImage_base64(result);
      debugger;
    });
  };

  const setTechnologyValue = () => {
    debugger;
    var technologyArray = location.state
      ? location.state.state.portfolioDataToEdit.portfoliotechnologies
      : null;
    let newArray = [];
    debugger;
    if (technologyArray) {
      for (let index = 0; index < technologyArray.length; index++) {
        const element = technologyArray[index];
        let object = {
          value: element.technologyId,
          label: element.technology.name,
        };
        newArray.push(object);
      }
      setTechnology((technology = newArray));
    }
  };

  const setServicesValue = () => {
    debugger;
    var servicesArray = location.state
      ? location.state.state.portfolioDataToEdit.portfolioservices
      : null;
    let newArray = [];
    debugger;
    if (servicesArray) {
      for (let index = 0; index < servicesArray.length; index++) {
        const element = servicesArray[index];
        let object = {
          value: element.serviceId,
          label: element.service.name,
        };
        newArray.push(object);
      }
      setServices((services = newArray));
    }
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setTitleErrorMessageAndVisibilityAndIsValid(title);
    if (viewValid)
      viewValid =
        setDescriptionErrorMessageAndVisibilityAndIsValid(description);
    if (viewValid)
      viewValid = setDetailErrorMessageAndVisibilityAndIsValid(detail);
    if (viewValid)
      viewValid = setTechnologyErrorMessageAndVisibilityAndIsValid(technology);
    if (viewValid)
      viewValid = setServicesErrorMessageAndVisibilityAndIsValid(services);
    return viewValid;
  };
  //#endregion

  //#region APi Call

  const getTechnologyApiCall = () => {
    let newArray = [];
    getTechnology()
      .then((res) => {
        if (ERROR_CODES.SUCCESS == res.data.errorCode) {
          setTechnologyArray((technologyArray = res.data.data));
          for (let index = 0; index < technologyArray.length; index++) {
            const element = technologyArray[index];
            let object = {
              value: element.id,
              label: element.name,
            };
            newArray.push(object);
          }
          setTechnologyOptions(newArray);
        }
      })
      .catch((error) => {});
  };

  const getServicesApiCall = () => {
    let newArray = [];
    getServices()
      .then((res) => {
        if (ERROR_CODES.SUCCESS == res.data.errorCode) {
          setServicesArray((servicesArray = res.data.data));
          for (let index = 0; index < servicesArray.length; index++) {
            const element = servicesArray[index];
            let object = {
              value: element.id,
              label: element.name,
            };
            newArray.push(object);
          }
          setServicesOptions(newArray);
        }
      })
      .catch((error) => {});
  };

  const addEditPortfolioApiCall = () => {
    let newTechnologyArray = [];
    let newServicesArray = [];
    if (technology) {
      for (let index = 0; index < technology.length; index++) {
        const element = technology[index];
        let object = {
          portfolioId: id,
          technologyId: element.value,
        };
        newTechnologyArray.push(object);
      }
    }
    if (services) {
      for (let index = 0; index < services.length; index++) {
        const element = services[index];
        let object = {
          portfolioId: id,
          serviceId: element.value,
        };
        newServicesArray.push(object);
      }
    }
    let data = {
      id: id,
      name: title,
      description: description,
      detail: detail,
      thumnail: Image_base64 ? Image_base64 : image,
      portfoliotechnologies: newTechnologyArray,
      portfolioservices: newServicesArray,
    };
    if (isViewValid()) {
      setIsLoading(true);
      addEditPortfolio(data)
        .then((res) => {
          setIsLoading(false);
          if (ERROR_CODES.SUCCESS == res.data.errorCode) {
            history.push("/dashboard/project-portfolio");
          } else {
            console.log(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };
  //#endregion

  const OptionsFeatured = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <div className="panel__conatainer">
      <div className="panel__heading">Add Portfolio</div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Name
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Name"
                  ClassName="input__box"
                  type="text"
                  value={title}
                  onChange={(event) => onTitleTextChangeListener(event)}
                />
                <div className="input__text__error__msg">
                  {titleErrorMessageVisibility ? titleErrorMessage : null}
                </div>
              </div>
            </div>
            {/* <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Image
                </div>
              </div>
              <div className="company__details__row__input__box">
                <UploadImage />
              </div>
            </div> */}
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Project Description
                </div>
              </div>
              <div className="company__details__row__input__box">
                <TextArea
                  value={description}
                  onChange={(value) => onDescriptionTextChangeListener(value)}
                />
                <div
                  className="input__text__error__msg"
                  style={{ bottom: "-19px" }}
                >
                  {descriptionErrorMessageVisibility
                    ? descriptionErrorMessage
                    : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Project Details
                </div>
              </div>
              <div className="company__details__row__input__box">
                <TextArea
                  value={detail}
                  onChange={(value) => onDetailTextChangeListener(value)}
                />
                <div
                  className="input__text__error__msg"
                  style={{ bottom: "-19px" }}
                >
                  {detailErrorMessageVisibility ? detailErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Technology Used
                </div>
              </div>
              <div className="company__details__row__input__box">
                <Select
                  isMulti
                  name="colors"
                  options={technologyOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={technology}
                  onChange={(event) => onTechnologyTextChangeListener(event)}
                />
                <div className="input__text__error__msg">
                  {technologyErrorMessageVisibility
                    ? technologyErrorMessage
                    : null}
                </div>
              </div>
            </div>

            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Services
                </div>
              </div>
              <div className="company__details__row__input__box">
                <Select
                  isMulti
                  name="colors"
                  options={servicesOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={services}
                  onChange={(event) => onServicesTextChangeListener(event)}
                />
                <div className="input__text__error__msg">
                  {servicesErrorMessageVisibility ? servicesErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Thumbnail
                </div>
              </div>
              <div className="company__details__row__input__box">
                <UploadImage
                  onImageSelection={(img) => {
                    debugger;
                    if (img) {
                      fileSelectedHandler(img);
                    } else {
                      setImage("");
                      setImage_base64("");
                    }
                  }}
                  thumbnailImageToPass={image}
                />
              </div>
            </div>

            {/* <div className="company__details__row">
            <div className="company__details__row__attribute">
              <div className="company__details__row__attribute__heading">
                Images
              </div>
            </div>
            <div className="company__details__row__input__box">
              <UploadImage />
            </div>
          </div> */}

            <div className="company__details__row" style={{ marginTop: "3em" }}>
              <div
                className="company__details__row__attribute"
                style={{ width: "300px" }}
              >
                <div
                  className="company__details__row__attribute__heading"
                  style={{ color: "transparent" }}
                >
                  Salary
                </div>
              </div>
              <div
                className="company__details__row__input__box"
                style={{ width: "200px" }}
              >
                <ButtonComponent
                  variant="btn"
                  placeholder="Save"
                  onClick={() => addEditPortfolioApiCall()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    //   </div>
  );
}
