import React from "react";
import SideBar from "../components/SideBar";
import "./Dashboard.scss";
import { Switch, Route } from "react-router-dom";
import DashboardPanel from "./DashboardPanel";
import ApplicantsPanel from "./ApplicantsPanel";
import JobListingPanel from "./JobListingPanel";
import JobPostingPanel from "./JobPostingPanel";
import FilterPanel from "./FilterPanel";
import Experience from "./Experience";
import ExperienceForm from "./ExperienceForm";
import Education from "./Education";
import EducationForm from "./EducationForm";
import Specialism from "./Specialism";
import SpecialisimForm from "./SpecialismForm";
import TrainingDirection from "./TrainingDirection";
import TrainingDirectionForm from "./TrainingDirectionForm";
import Client from "./Client";
import ClientForm from "./ClientForm";
import Testimonials from "./Testimonials";
import TestimonialsForm from "./TestimonialsForm";
import TechnologyStack from "./TechnologyStack";
import TechnologyStackform from "./TechnologyStackForm";
import Services from "./Services";
import ServicesForm from "./ServicesForm";
import Portfolio from "./Portfolio";
import PortfolioForm from "./PortfolioForm";
import Quotations from "./Quotations";
import Blogs from "./Blogs";
import BlogsForm from "./BlogsForm";
import PortfolioDetail from "./PortfolioDetail";
import Category from "./Category";
import CategoryForm from "./CategoryForm";
import ContactUs from "./ContactUs";
import JobTypePanel from "./JobType";
import JobTypeForm from "./JobTypeForm";
import JobSkillPanel from "./JobSkill";
import JobSkillForm from "./JobSkillForm";
import IndustriesPanel from "./Industries";
import IndustryForm from "./IndustriesForm";
import ImpactPanel from "./Impact";
import ImpactForm from "./ImpactForm";
import RequestProposals from "./RequestProposals";
import Subscriber from "./Subscriber";
export default class Dashboard extends React.Component {
  render() {
    return (
      <div className="dasboard__container">
        <SideBar />
        <Switch>
          <Route path="/dashboard" exact>
            <DashboardPanel />
          </Route>
          <div className="main">
            <Route path="/dashboard/job-posting">
              <JobPostingPanel />
            </Route>
            <Route path="/dashboard/job-listing">
              <JobListingPanel />
            </Route>
            <Route path="/dashboard/applicants">
              <ApplicantsPanel />
            </Route>
            <Route path="/dashboard/filters">
              <FilterPanel />
            </Route>
            <Route path="/dashboard/experience">
              <Experience />
            </Route>
            <Route path="/dashboard/experience-edit">
              <ExperienceForm />
            </Route>
            <Route path="/dashboard/education">
              <Education />
            </Route>
            <Route path="/dashboard/jobtype">
              <JobTypePanel />
            </Route>
            <Route path="/dashboard/jobtype-edit">
              <JobTypeForm />
            </Route>
            <Route path="/dashboard/jobSkill">
              <JobSkillPanel />
            </Route>
            <Route path="/dashboard/jobSkill-edit">
              <JobSkillForm />
            </Route>
            <Route path="/dashboard/education-edit">
              <EducationForm />
            </Route>
            <Route path="/dashboard/category">
              <Category />
            </Route>
            <Route path="/dashboard/category-edit">
              <CategoryForm />
            </Route>
            <Route path="/dashboard/specialism">
              <Specialism />
            </Route>
            <Route path="/dashboard/specialism-edit">
              <SpecialisimForm />
            </Route>
            <Route path="/dashboard/training-direction">
              <TrainingDirection />
            </Route>
            <Route path="/dashboard/training-direction-edit">
              <TrainingDirectionForm />
            </Route>
            <Route path="/dashboard/client">
              <Client />
            </Route>
            <Route path="/dashboard/client-edit">
              <ClientForm />
            </Route>
            <Route path="/dashboard/testimonials">
              <Testimonials />
            </Route>
            <Route path="/dashboard/testimonial-edit">
              <TestimonialsForm />
            </Route>
            <Route path="/dashboard/industries">
              <IndustriesPanel />
            </Route>
            <Route path="/dashboard/industry-edit">
              <IndustryForm />
            </Route>
            <Route path="/dashboard/impact">
              <ImpactPanel />
            </Route>
            <Route path="/dashboard/impact-edit">
              <ImpactForm />
            </Route>
            <Route path="/dashboard/technology-stack">
              <TechnologyStack />
            </Route>
            <Route path="/dashboard/technology-stack-edit">
              <TechnologyStackform />
            </Route>
            <Route path="/dashboard/services">
              <Services />
            </Route>
            <Route path="/dashboard/services-edit">
              <ServicesForm />
            </Route>
            <Route path="/dashboard/project-portfolio">
              <Portfolio />
            </Route>
            <Route path="/dashboard/project-portfolio-edit">
              <PortfolioForm />
            </Route>
            <Route path="/dashboard/project-portfolio-detail">
              <PortfolioDetail />
            </Route>
            <Route path="/dashboard/quotations">
              <Quotations />
            </Route>
            <Route path="/dashboard/blogs">
              <Blogs />
            </Route>
            <Route path="/dashboard/contacts">
              <ContactUs />
            </Route>
            <Route path="/dashboard/proposals">
              <RequestProposals />
            </Route>
            <Route path="/dashboard/blogs-edit">
              <BlogsForm />
            </Route>
            <Route path="/dashboard/subscribers">
              <Subscriber />
            </Route>
          </div>
        </Switch>
      </div>
    );
  }
}
