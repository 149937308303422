import React, { useEffect, useState } from "react";
import "./JobPostingPanel.scss";
import ButtonComponent from "../components/Button";
import InputBox from "../components/Inputbox";
import UploadImage from "../components/UploadImage";
import { ERROR_CODES } from "../Constants";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { useHistory, useLocation } from "react-router-dom";
import { addClient, editClient } from "../APIs/Repos"; // Import both API calls
import isNullOrEmpty from "../Utils/TextUtils";

export default function ClientForm() {
  //#region Variables

  const history = useHistory();
  const location = useLocation();

  const [id, setId] = useState(
    location.state ? location.state.state.clientDataToEdit.id : 0
  );

  const [name, setName] = useState(
    location.state ? location.state.state.clientDataToEdit.name : ""
  );
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] =
    useState(false);

  const [image, setImage] = useState(
    location.state ? location.state.state.clientDataToEdit.client_image : ""
  );
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [imageErrorMessageVisibility, setImageErrorMessageVisibility] =
    useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [website, setWebsite] = useState(
    location.state ? location.state.state.clientDataToEdit.website : ""
  );
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState("");
  const [websiteErrorMessageVisibility, setWebsiteErrorMessageVisibility] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  //#endregion
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    
    if (location.state && location.state.state.clientDataToEdit) {
      const { _id, name, website, client_image } = location.state.state.clientDataToEdit;
      setId(_id);
      setName(name);
      setWebsite(website);
      setImagePreview(process.env.REACT_APP_API_URL +client_image);
    }
  }, [location.state]);
  //#region API Calls

  const addClientApiCall = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("client_image", image);
    formData.append("website", website);

    if (isViewValid()) {
      setIsLoading(true);
      addClient(formData)
        .then((res) => {
          setIsLoading(false);
          if (ERROR_CODES.ADD_EDIT_SUCCESS === res.data.error_code) {
            history.push("/dashboard/client");
          } else {
            console.log(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const editClientApiCall = () => {
    const formData = new FormData();
    formData.append("_id", id);
    formData.append("name", name);
    formData.append("client_image", image);
    formData.append("website", website);

    if (isViewValid()) {
      setIsLoading(true);
      editClient(formData)
        .then((res) => {
          setIsLoading(false);
          if (ERROR_CODES.ADD_EDIT_SUCCESS === res.data.error_code) {
            history.push("/dashboard/client");
          } else {
            console.log(res.data.errorMessage);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const addEditClientApiCall = () => {
    if (id) {
      editClientApiCall();
    } else {
      addClientApiCall();
    }
  };

  //#endregion

  //#region Validation
  const onNameTextChangeListener = (event) => {
    const enteredName = event.currentTarget.value;
    setName(enteredName);
    setNameErrorMessageAndVisibilityAndIsValid(enteredName);
  };

  const onWebsiteTextChangeListener = (event) => {
    const enteredWebsite = event.currentTarget.value;
    setWebsite(enteredWebsite);
    setWebsiteErrorMessageAndVisibilityAndIsValid(enteredWebsite);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setNameErrorMessageAndVisibility(true, "Enter Name");
      return false;
    } else {
      setNameErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setWebsiteErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setWebsiteErrorMessageAndVisibility(true, "Enter Website URL");
      return false;
    } else {
      setWebsiteErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setNameErrorMessageAndVisibility = (visibility, text) => {
    setNameErrorMessageVisibility(visibility);
    setNameErrorMessage(text);
  };

  const setWebsiteErrorMessageAndVisibility = (visibility, text) => {
    setWebsiteErrorMessageVisibility(visibility);
    setWebsiteErrorMessage(text);
  };

  const isViewValid = () => {
    const isNameValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    const isWebsiteValid =
      setWebsiteErrorMessageAndVisibilityAndIsValid(website);
    return isNameValid && isWebsiteValid;
  };
  //#endregion

  //#region Other Functions

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  const onImageFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setImageErrorMessageAndVisibility(false, "");
    } else {
      setImageErrorMessageAndVisibility(true, "Select an image file");
    }
  };

  const setImageErrorMessageAndVisibility = (visibility, text) => {
    setImageErrorMessageVisibility(visibility);
    setImageErrorMessage(text);
  };

  //#endregion

  return (
    <div className="panel__container">
      <div className="panel__heading">Add/Edit Client</div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Name
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Name"
                  className="input__box"
                  type="text"
                  value={name}
                  onChange={onNameTextChangeListener}
                />
                {nameErrorMessageVisibility && (
                  <div className="input__text__error__msg">
                    {nameErrorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Logo
                </div>
              </div>
              <div className="company__details__row__input__box">
                <input
                  type="file"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
                <div className="input__text__error__msg">
                  {imageErrorMessageVisibility ? imageErrorMessage : null}
                </div>
                {imagePreview && (
                  <div className="image__preview">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        padding: "10px",
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Website
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Website"
                  className="input__box"
                  type="text"
                  value={website}
                  onChange={onWebsiteTextChangeListener}
                />
                {websiteErrorMessageVisibility && (
                  <div className="input__text__error__msg">
                    {websiteErrorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="category__details__row" style={{ marginTop: "3em" }}>
              <div className="category__details__row__attribute" style={{ width: "300px" }}>
                <div className="category__details__row__attribute__heading" style={{ color: "transparent" }}>
                  {/* Placeholder */}
                </div>
              </div>
              <div className="category__details__row__input__box" style={{ width: "200px" }}>
                <ButtonComponent
                  variant="btn"
                  placeholder="Save"
                  onClick={addEditClientApiCall}
                />
              </div>
            </div>
        </div>
      )}
    </div>
  );
}
