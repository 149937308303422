import React, { useEffect, useState } from "react";
import "./Login.scss";
import logo from '../assets/logo.svg';
import { Form } from "react-bootstrap";
import Inputbox from "../components/Inputbox";
import Button from "../components/Button";
import { login } from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";
import { useHistory } from "react-router-dom";
import isNullOrEmpty from "../Utils/TextUtils";

export default function Login() {
  //#region Variables
  const history = useHistory();
  let [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailErrorMessageVisibility, setEmailErrorMessageVisibility] =
    useState(false);

  let [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordErrorMessageVisibility, setPasswordErrorMessageVisibility] =
    useState(false);

  const [apiErrorMessage, setApiErrorMessage] = useState(""); // Added for API errors
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region API Call
  const loginApiCall = () => {
    let data = {
      email: email,
      password: password,
    };
    if (isViewValid()) {
      setIsLoading(true);
      login(data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.error_code === ERROR_CODES.SUCCESS) {
            localStorage.setItem("adminId", res.data.result.user._id);
            localStorage.setItem("rememberMe", true);
            history.push("/dashboard");
          } else {
            console.log(res.data);
            
            setApiErrorMessage(res.data.message || "An error occurred.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setApiErrorMessage("An error occurred. Please try again.");
          console.log(error);
        });
    }
  };
  //#endregion

  //#region Email Validation
  const onEmailTextChangeListener = (event) => {
    setEmail(event.currentTarget.value);
    setEmailErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };
  const setEmailErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    let isValid = false;
    if (isNullOrEmpty(enteredText)) {
      setEmailErrorMessageAndVisibility(true, "Enter Email");
    } else {
      setEmailErrorMessageAndVisibility(false, "");
      isValid = true;
    }
    return isValid;
  };

  const setEmailErrorMessageAndVisibility = (visibility, text) => {
    setEmailErrorMessageVisibility(visibility);
    setEmailErrorMessage(text);
  };

  //#endregion

  //#region Password Validation
  const onPasswordTextChangeListener = (event) => {
    setPassword(event.currentTarget.value);
    setPasswordErrorMessageAndVisibilityAndIsValid(event.currentTarget.value);
  };
  const setPasswordErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    let isValid = false;
    if (isNullOrEmpty(enteredText)) {
      setPasswordErrorMessageAndVisibility(true, "Enter Password");
    } else {
      setPasswordErrorMessageAndVisibility(false, "");
      isValid = true;
    }
    return isValid;
  };

  const setPasswordErrorMessageAndVisibility = (visibility, text) => {
    setPasswordErrorMessageVisibility(visibility);
    setPasswordErrorMessage(text);
  };

  //#endregion

  //#region View Valid
  const isViewValid = () => {
    let viewValid = setEmailErrorMessageAndVisibilityAndIsValid(email);
    if (viewValid)
      viewValid = setPasswordErrorMessageAndVisibilityAndIsValid(password);
    return viewValid;
  };
  //#endregion

  //#region other functions

  useEffect(() => {
    if (localStorage.getItem("adminId")) history.push("/dashboard");
  }, [history]);

  //#endregion

  return (
    <div className="login__container">
      <Form className="login__form">
        <div className="logoDiv">
          <img className="logo" src={logo} alt="Logo" />
        </div>
        <div className="login__form__heading">Login</div>
        <div className="login__form__row" style={{ marginBottom: '10px' }}>
          <Inputbox
            placeholder="Email"
            type="email"
            ClassName="input__box"
            value={email}
            onChange={onEmailTextChangeListener}
            onKeyDown={(event) => {
              if (event.code === "Enter") {
                loginApiCall();
              }
            }}
          />
          <div className="input__text__error__msg_login">
            {emailErrorMessageVisibility ? emailErrorMessage : null}
          </div>
        </div>
        <div className="login__form__row">
          <Inputbox
            placeholder="Password"
            type="password"
            ClassName="input__box"
            value={password}
            onChange={onPasswordTextChangeListener}
            onKeyDown={(event) => {
              if (event.code === "Enter") {
                loginApiCall();
              }
            }}
          />
          <div className="input__text__error__msg_login" style={{ bottom: "-1.5em" }}>
            {passwordErrorMessageVisibility ? passwordErrorMessage : null}
          </div>
        </div>
        <div className="login__form__row__cta">
          <input
            className="styled-checkbox"
            id="styled-checkbox-1"
            type="checkbox"
            value="value1"
          />
          <label htmlFor="styled-checkbox-1">Remember Me</label>
        </div>
        <Button
          placeholder={isLoading ? "Processing..." : "Login"}
          variant="btn"
          onClick={() => {
            if (!isLoading) loginApiCall();
          }}
        />
        {apiErrorMessage && (
          <div className="api-error-message">
            {apiErrorMessage}
          </div>
        )}
      </Form>
    </div>
  );
}
