import React, { useEffect, useState } from "react";
import "./ApplicantsPanel.scss";
import { Nav } from "react-bootstrap";
import { Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import Button from "../components/Button";
import userPic from "../assets/userPic.png";
import { getPortfolioImages, addEditPortfolioImages } from "../APIs/Repos";
import { ERROR_CODES } from "../Constants";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { X, Plus } from "react-feather";
import { getBase64 } from "../Utils/TextUtils";

function UploadImage({
  onImageSelection,
  thumbnailImageToPass,
  onUploadingImageCallBack,
}) {
  //#region Variables
  let [upload, setUpload] = useState("");
  let [receivedImage, setReceivedImage] = useState("");
  let [uploadedArray, setUploadedArray] = useState("");
  //#endregion

  //#region other functions

  useEffect(() => {
    if (thumbnailImageToPass == null || thumbnailImageToPass == "") {
      setUpload("");
      setReceivedImage("");
    } else {
      setUpload("props");
      setReceivedImage(thumbnailImageToPass);
    }
  }, [thumbnailImageToPass]);

  const onUploadImageChangeListener = (e) => {
    console.log("selected image", e.target.files);
    setUploadedArray((uploadedArray = e.target.files));
    if (uploadedArray.length > 15) {
      console.log("Select maximum 15 images");
    } else {
      setUpload((upload = e.target.files[0]));
      onUploadingImageCallBack(uploadedArray);
    }
  };

  const onCrossButtonClickListener = () => {};
  //#endregion

  return (
    <div className="popup__form__upload__wrapper">
      {/* <div className="popup__form__input__label">label</div> */}
      <div className="popup__form__upload" style={{ width: "fit-content" }}>
        {upload === "" ? null : (
          <button
            className="popup__form__upload__btn"
            type="button"
            onClick={() => {
              onCrossButtonClickListener();
            }}
          >
            <X size={15} color="currentColor" />
          </button>
        )}
        <input
          type="file"
          className="popup__form__upload__input"
          onChange={(e) => onUploadImageChangeListener(e)}
          multiple={true}
        />
        <div className="popup__form__upload__content">
          {upload === "" ? (
            <Plus size={30} color="currentcolor" />
          ) : (
            <img
              src={
                upload == "props"
                  ? process.env.REACT_APP_API_URL + receivedImage
                  : URL.createObjectURL(upload)
              }
              // src={URL.createObjectURL(upload)}
              alt=""
              className="popup__form__upload__content__img"
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ApplicantsRow({ imagesToPass }) {
  return (
    <div style={{ width: "23%", margin: "1em 0em" }}>
      {imagesToPass.image ? (
        <img src={process.env.REACT_APP_API_URL + imagesToPass.image} />
      ) : null}
    </div>
  );
}

export default function PortfolioDetail() {
  const location = useLocation();
  const [portfolioId, setPortfolioId] = useState(
    location.state.state.portfolioDetailToPass.id
  );
  const [imagesArray, setImagesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let [uploadedImagesArray, setUploadedImagesArray] = useState(null);

  useEffect(() => {
    getPortfolioImagesApiCall();
  }, []);

  const getPortfolioImagesApiCall = () => {
    setIsLoading(true);
    getPortfolioImages(portfolioId)
      .then((res) => {
        setIsLoading(false);
        if (res.data.errorCode == ERROR_CODES.SUCCESS) {
          setImagesArray(res.data.data);
        } else {
          // console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const addEditPortfolioImagesAPiCall = (array) => {
    let data = {
      projectImages: array,
    };
    debugger;
    addEditPortfolioImages(data)
      .then((res) => {
        if (res.data.errorCode == ERROR_CODES.SUCCESS) {
          getPortfolioImagesApiCall();
        } else {
          console.log(res.data.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;
  const fileSelectedHandler = (img) => {
    let newArray = [];
    debugger;
    for (let index = 0; index < img.length; index++) {
      const element = img[index];
      getBase64(element, (result) => {
        debugger;
        let object = {
          portfolioId: portfolioId,
          image: result,
        };
        newArray.push(object);
        setUploadedImagesArray((uploadedImagesArray = newArray));
        debugger;
        if (uploadedImagesArray.length == img.length)
          addEditPortfolioImagesAPiCall(uploadedImagesArray);
        debugger;
      });
    }
  };
  return (
    <div className="panel__conatainer">
      <div className="panel__heading">
        Images{" "}
        <span style={{ float: "right", width: "100px" }}>
          {/* <Button
            variant="link"
            toPath="/dashboard/blogs-edit"
            placeholder="Add New"
          /> */}
        </span>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {/* <div className="panel__main__heading__row">
            <div className="panel__main__heading__row__entry">Title</div>
            <div className="panel__main__heading__row__entry">Posted By</div>
            <div className="panel__main__heading__row__entry">
              Short Description
            </div>
            <div className="panel__main__heading__row__entry">
              Blog Description
            </div>
            <div className="panel__main__heading__row__entry">Thumbnail</div>
          </div> */}
        {isLoading ? (
          <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
        ) : imagesArray != null && imagesArray.length > 0 ? (
          imagesArray.map((data) => <ApplicantsRow imagesToPass={data} />)
        ) : null}
      </div>
      <UploadImage
        onUploadingImageCallBack={(images) => {
          fileSelectedHandler(images);
        }}
      />
    </div>
  );
}
