import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import logoImage from "../assets/logo.svg";

export default function SideBar() {
  const location = useLocation();
  const history = useHistory();

  const onLogoutClickListener = () => {
    localStorage.removeItem("adminId");
    localStorage.removeItem("rememberMe");
    history.push("/");
  };

  useEffect(() => {
    if (!localStorage.getItem("adminId")) {
      history.push("/");
    }
  }, [history]);

  const isActive = (path) => location.pathname === path;

  return (
    <div className="side__bar">
      <div className="parent_side__bar__heading">
        <img className="side__bar__heading" src={logoImage} alt="logo" />
      </div>
      <Nav
        variant="pills"
        className="sidebar__links"
        defaultActiveKey="/dashboard"
      >
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard"
            className={`nav__link__vertical ${
              isActive("/dashboard") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 23.002 19.822"
            >
              <path
                id="Icon_material-dashboard"
                data-name="Icon material-dashboard"
                d="M4.5,15.512H14.723V4.5H4.5Zm0,8.81H14.723V17.714H4.5Zm12.779,0H27.5V13.31H17.279Zm0-19.822v6.607H27.5V4.5Z"
                transform="translate(-4.5 -4.5)"
              />
            </svg>
            Dashboard
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/education"
            className={`nav__link__vertical ${
              isActive("/dashboard/education") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-book"
            >
              <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
              <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
            </svg>
            Education
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/jobtype"
            className={`nav__link__vertical ${
              isActive("/dashboard/jobtype") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-briefcase"
            >
              <path d="M4 4h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z" />
              <path d="M16 2v4H8V2M12 12h4v4h-4zm-4 0h4v4H8zm-2 0H4v4h2zm0-2v2H4v-2h2zm16 0v2h-2v-2h2z" />
            </svg>
            Job Type
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/jobSkill"
            className={`nav__link__vertical ${
              isActive("/dashboard/jobSkill") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon"
            >
              <path d="M12 2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2 2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z"></path>
              <path d="M5 11h14v2H5v-2z"></path>
              <path d="M5 15h14v2H5v-2z"></path>
              <path d="M5 19h14v2H5v-2z"></path>
            </svg>
            Job Skills
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/category"
            className={`nav__link__vertical ${
              isActive("/dashboard/category") ? "active" : ""
            }`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3h18v2H3V3zm0 4h18v2H3V7zm0 4h18v2H3v-2zm0 4h18v2H3v-2zm0 4h18v2H3v-2z"
                fill="#000"
              />
            </svg>
            Category
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/job-posting"
            className={`nav__link__vertical ${
              isActive("/dashboard/job-posting") ? "active" : ""
            }`}
          >
            <svg
              id="files"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 28.962 26.314"
            >
              <path
                id="Path_26"
                data-name="Path 26"
                d="M905.359,2347.677h22.913l3.025-19.154H902.335Zm20.777-2.5H907.5l-2.235-14.154h23.11Z"
                transform="translate(-902.335 -2321.363)"
              />
              <rect
                id="Rectangle_17"
                data-name="Rectangle 17"
                width="10.086"
                height="2.5"
                transform="translate(9.437 12.79)"
              />
              <rect
                id="Rectangle_18"
                data-name="Rectangle 18"
                width="23.925"
                height="2.5"
                transform="translate(2.518 3.58)"
              />
              <rect
                id="Rectangle_19"
                data-name="Rectangle 19"
                width="18.452"
                height="2.5"
                transform="translate(5.255)"
              />
            </svg>
            Job Posting
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/job-listing"
            className={`nav__link__vertical ${
              isActive("/dashboard/job-listing") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 26.373 21.428"
            >
              <path
                id="Icon_awesome-list"
                data-name="Icon awesome-list"
                d="M4.121,19.858H.824A.824.824,0,0,0,0,20.682v3.3a.824.824,0,0,0,.824.824h3.3a.824.824,0,0,0,.824-.824v-3.3A.824.824,0,0,0,4.121,19.858Zm0-16.483H.824A.824.824,0,0,0,0,4.2V7.5a.824.824,0,0,0,.824.824h3.3A.824.824,0,0,0,4.945,7.5V4.2A.824.824,0,0,0,4.121,3.375Zm0,8.242H.824A.824.824,0,0,0,0,12.441v3.3a.824.824,0,0,0,.824.824h3.3a.824.824,0,0,0,.824-.824v-3.3A.824.824,0,0,0,4.121,11.617Zm21.428,9.066H9.066a.824.824,0,0,0-.824.824v1.648a.824.824,0,0,0,.824.824H25.549a.824.824,0,0,0,.824-.824V21.506A.824.824,0,0,0,25.549,20.682Zm0-16.483H9.066a.824.824,0,0,0-.824.824V6.672a.824.824,0,0,0,.824.824H25.549a.824.824,0,0,0,.824-.824V5.023A.824.824,0,0,0,25.549,4.2Zm0,8.242H9.066a.824.824,0,0,0-.824.824v1.648a.824.824,0,0,0,.824.824H25.549a.824.824,0,0,0,.824-.824V13.265A.824.824,0,0,0,25.549,12.441Z"
                transform="translate(0 -3.375)"
              />
            </svg>
            Job Listing
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/client"
            className={`nav__link__vertical ${
              isActive("/dashboard/client") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-users"
            >
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
            Client
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/testimonials"
            className={`nav__link__vertical ${
              isActive("/dashboard/testimonials") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-wind"
            >
              <path d="M9.59 4.59A2 2 0 1 1 11 8H2m10.59 11.41A2 2 0 1 0 14 16H2m15.73-8.27A2.5 2.5 0 1 1 19.5 12H2"></path>
            </svg>
            Testimonials
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/impact"
            className={`nav__link__vertical ${
              isActive("/dashboard/impact") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-slack"
            >
              <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z"></path>
              <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
              <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z"></path>
              <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z"></path>
              <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z"></path>
              <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"></path>
              <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z"></path>
              <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z"></path>
            </svg>
            Impact
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/industries"
            className={`nav__link__vertical ${
              isActive("/dashboard/industries") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-monitor"
            >
              <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
              <line x1="8" y1="21" x2="16" y2="21"></line>
              <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
            Industries
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/applicants"
            className={`nav__link__vertical ${
              isActive("/dashboard/applicants") ? "active" : ""
            }`}
          >
            <svg
              id="Group_15"
              data-name="Group 15"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 32.436 26.224"
            >
              <path
                id="Path_18"
                data-name="Path 18"
                d="M297.27,2238.051a5.775,5.775,0,0,0-3.577,1.249,7.244,7.244,0,1,0-.282,8.858,5.791,5.791,0,1,0,3.859-10.107Zm-9.444,10.24a4.75,4.75,0,1,1,4.75-4.749A4.755,4.755,0,0,1,287.826,2248.291Zm9.444-1.153a3.255,3.255,0,0,1-2.578-1.286,7.081,7.081,0,0,0,.131-4.176,3.283,3.283,0,1,1,2.447,5.462Z"
                transform="translate(-274.941 -2236.292)"
              />
              <path
                id="Path_19"
                data-name="Path 19"
                d="M297.27,2249.869a11.119,11.119,0,0,0-6.86,2.251,15.53,15.53,0,0,0-2.584-.221c-6.48,0-11.932,3.951-12.681,9.19l-.2,1.427h25.77l-.2-1.427a8.833,8.833,0,0,0-.795-2.572h7.665l-.2-1.427C306.584,2252.974,302.327,2249.869,297.27,2249.869ZM278,2260.016c1.284-3.285,5.227-5.617,9.829-5.617s8.544,2.332,9.829,5.617Zm23.865-4h-3.868a12.5,12.5,0,0,0-4.291-2.944,9.32,9.32,0,0,1,3.567-.7c3.161,0,5.893,1.493,6.977,3.648Z"
                transform="translate(-274.941 -2236.292)"
              />
            </svg>
            CV Banks
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/contacts"
            className={`nav__link__vertical ${
              isActive("/dashboard/contacts") ? "active" : ""
            }`}
          >
            <svg
              id="contact-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              width="32px"
              height="32px"
            >
              <path d="M21 8.5c.5-.4.9-.9 1.2-1.5.2-.4.3-.9.3-1.3 0-1.7-1.3-3-3-3H4c-1.7 0-3 1.3-3 3 0 .4.1.9.3 1.3.3.6.7 1.1 1.2 1.5v8.5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8.5zM21 7c-.3.4-.7.7-1.1 1L13 12.9c-.3.2-.6.3-.9.3s-.6-.1-.9-.3L4.1 8c-.4-.3-.8-.6-1.1-1v-2c0-.6.4-1 1-1h16c.6 0 1 .4 1 1V7zM12 13.5c.6 0 1.2-.2 1.7-.5l7.1-5c.3-.2.7-.3 1.2-.1.3.1.6.3.8.5.1.1.2.2.2.3v7.5c0 .5-.5 1-1 1H4c-.5 0-1-.5-1-1V9c0-.1.1-.2.2-.3.2-.2.5-.4.8-.5.5-.2.9-.1 1.2.1l7.1 5c.5.3 1.1.5 1.7.5z" />
            </svg>
            Contacts
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/proposals"
            className={`nav__link__vertical ${
              isActive("/dashboard/proposals") ? "active" : ""
            }`}
          >
            <svg
              id="requested-proposals-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              width="32px"
              height="32px"
            >
              <path d="M6 2H4a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8l-6-6H6zm0 2h8v6h6v12H6V4zm9 7h-4v2h4v-2zm4.707 2.707a1 1 0 0 1-1.414 0L14 12.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L12.586 11l-2.293-2.293a1 1 0 0 1 1.414-1.414L14 9.586l2.293-2.293a1 1 0 0 1 1.414 1.414L15.414 11l2.293 2.293a1 1 0 0 1 0 1.414z" />
            </svg>
            Requested Proposals
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard/subscribers"
            className={`nav__link__vertical ${
              isActive("/dashboard/subscribers") ? "active" : ""
            }`}
          >
            <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 24 24"
  width="32px"
  height="32px"
>
  <path d="M12 2C9.243 2 7 4.243 7 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm6 8h-1v-1c0-2.757-2.243-5-5-5s-5 2.243-5 5v1H6v-1c0-3.313 2.687-6 6-6s6 2.687 6 6v1z" />
</svg>

            Subscribers
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="side__bar__cta" onClick={() => onLogoutClickListener()}>
        <Nav.Link className="nav__link__vertical">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.81 28.805">
            <g id="loguot" transform="translate(29.81 28.805) rotate(180)">
              <path
                id="Path_31"
                data-name="Path 31"
                d="M0,7.479H13.665l-3.457,3.127,1.677,1.854,6.89-6.23L11.885,0,10.208,1.854l3.457,3.126H0Z"
                transform="translate(0 8.173)"
                fill="currentColor"
              />
              <path
                id="Path_32"
                data-name="Path 32"
                d="M24.313,0H0V6.1H2.5V2.5H21.813V26.3H2.5v-3.66H0V28.8H24.313Z"
                transform="translate(5.496)"
                fill="currentColor"
              />
            </g>
          </svg>
          Logout
        </Nav.Link>
      </div>
    </div>
  );
}
