import React, { useState, useEffect } from "react";
import "./ApplicantsPanel.scss";
import Button from "../components/Button";
import InputBox from "../components/Inputbox";
import { useHistory, useLocation } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import axios from "axios";
import isNullOrEmpty from "../Utils/TextUtils";
import { ERROR_CODES } from "../Constants";

export default function ImpactForm() {
  const history = useHistory();
  const location = useLocation();

  const [_id, setId] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [industriesErrorMessage, setIndustriesErrorMessage] = useState("");
  const [industriesErrorMessageVisibility, setIndustriesErrorMessageVisibility] = useState(false);
  const [pool, setPool] = useState(null);
  const [poolErrorMessage, setPoolErrorMessage] = useState("");
  const [poolErrorMessageVisibility, setPoolErrorMessageVisibility] = useState(false);
  const [partnerships, setPartnerships] = useState(null);
  const [partnershipsErrorMessage, setPartnershipsErrorMessage] = useState("");
  const [partnershipsErrorMessageVisibility, setPartnershipsErrorMessageVisibility] = useState(false);
  const [experience, setExperience] = useState(null);
  const [experienceErrorMessage, setExperienceErrorMessage] = useState("");
  const [experienceErrorMessageVisibility, setExperienceErrorMessageVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state && location.state.state.impactDataToEdit) {
      const { _id, industries, pool, partnerships, experience } = location.state.state.impactDataToEdit;
      setId(_id);
      setIndustries(industries);
      setPool(pool);
      setPartnerships(partnerships);
      setExperience(experience);
    }
  }, [location.state]);

  const addEditImpactApiCall = async () => {
    if (!isViewValid()) return;

    const data = {
      ...( _id && { _id: _id }),  // Only include _id if it exists
      industries: industries,
      pool: pool,
      partnerships: partnerships,
      experience: experience,
    };
    setIsLoading(true);
    try {
      const response = _id
        ? await axios.post(`${process.env.REACT_APP_API_URL}impactNo/update-impactNo`, data)
        : await axios.post(`${process.env.REACT_APP_API_URL}impactNo/add-impactNo`, data);

      setIsLoading(false);
      if (response.data.error_code === ERROR_CODES.SUCCESS) {
        history.push("/dashboard/impact");
      } else {
        console.log(response.data.errorMessage);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const isViewValid = () => {
    let viewValid = setIndustriesErrorMessageAndVisibilityAndIsValid(industries);
    if (viewValid) viewValid = setPoolErrorMessageAndVisibilityAndIsValid(pool);
    if (viewValid) viewValid = setPartnershipsErrorMessageAndVisibilityAndIsValid(partnerships);
    if (viewValid) viewValid = setExperienceErrorMessageAndVisibilityAndIsValid(experience);
    return viewValid;
  };


  const setIndustriesErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setIndustriesErrorMessageAndVisibility(true, "Enter Industries");
      return false;
    } else {
      setIndustriesErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setIndustriesErrorMessageAndVisibility = (visibility, text) => {
    setIndustriesErrorMessageVisibility(visibility);
    setIndustriesErrorMessage(text);
  };

  const setPoolErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setPoolErrorMessageAndVisibility(true, "Enter Pool");
      return false;
    } else {
      setPoolErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setPoolErrorMessageAndVisibility = (visibility, text) => {
    setPoolErrorMessageVisibility(visibility);
    setPoolErrorMessage(text);
  };

  const setPartnershipsErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setPartnershipsErrorMessageAndVisibility(true, "Enter Partnership");
      return false;
    } else {
      setPartnershipsErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setPartnershipsErrorMessageAndVisibility = (visibility, text) => {
    setPartnershipsErrorMessageVisibility(visibility);
    setPartnershipsErrorMessage(text);
  };

  const setExperienceErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    if (isNullOrEmpty(enteredText)) {
      setExperienceErrorMessageAndVisibility(true, "Enter Experience");
      return false;
    } else {
      setExperienceErrorMessageAndVisibility(false, "");
      return true;
    }
  };

  const setExperienceErrorMessageAndVisibility = (visibility, text) => {
    setExperienceErrorMessageVisibility(visibility);
    setExperienceErrorMessage(text);
  };

  const override = css`
    display: block;
    border-color: #6ba53a;
    margin: 15em auto;
  `;

  return (
    <div className="panel__container">
      <div className="panel__heading">
        {_id ? "Edit Impact" : "Add Impact"}
      </div>
      {isLoading ? (
        <div className="loader-container">
          <RingLoader size={150} color={"rgb(194,161,71)"} />
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className="company__details__content">
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Industries
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Industries"
                  ClassName="input__box"
                  type="number"
                  value={industries}
                  onChange={(e) => setIndustries(e.currentTarget.value)}
                />
                <div className="input__text__error__msg">
                  {industriesErrorMessageVisibility ? industriesErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Pool
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Pool"
                  ClassName="input__box"
                  type="number"
                  value={pool}
                  onChange={(e) => setPool(e.currentTarget.value)}
                />
                <div className="input__text__error__msg">
                  {poolErrorMessageVisibility ? poolErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Partnership
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Partnership"
                  ClassName="input__box"
                  type="number"
                  value={partnerships}
                  onChange={(e) => setPartnerships(e.currentTarget.value)}
                />
                <div className="input__text__error__msg">
                  {partnershipsErrorMessageVisibility ? partnershipsErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="company__details__row">
              <div className="company__details__row__attribute">
                <div className="company__details__row__attribute__heading">
                  Experience
                </div>
              </div>
              <div className="company__details__row__input__box">
                <InputBox
                  placeholder="Experience"
                  ClassName="input__box"
                  type="number"
                  value={experience}
                  onChange={(e) => setExperience(e.currentTarget.value)}
                />
                <div className="input__text__error__msg">
                  {experienceErrorMessageVisibility ? experienceErrorMessage : null}
                </div>
              </div>
            </div>
            <div className="form__action__buttons">
              <Button 
              variant="btn"
               placeholder="Save"
              onClick={addEditImpactApiCall}>
                {_id ? "Update Impact" : "Add Impact"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
